import { navigateToUrl, useDashboardStore } from "@taxbit-dashboard/commons";
import { useCallback, useMemo } from "react";

import isDownloadReportButtonDisabled from "./isDownloadReportButtonDisabled";
import { useGetTaxReportUrl } from "../../../api/taxReportsApi";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import { TaxReport } from "../taxReportTypes";
import { useReportsDataContext } from "../useReportsData";

export const getDownloadReportErrorToastTrackingId = (key: string) =>
  `download-report-error-toast-${key}`;

export const useDownloadReport = (report: TaxReport) => {
  const { getReportStatus } = useReportsDataContext();
  const addToast = useDashboardStore((store) => store.addToast);

  const { isFetching: isLoading, refetch: refetchTaxReportUrl } =
    useGetTaxReportUrl({
      reportType: report.key,
      onError: () => {
        addToast({
          message: `${report.name} failed to download. Please try again.`,
          trackingId: getDownloadReportErrorToastTrackingId(report.key),
          variant: "danger",
          timeoutMs: TOAST_TIMEOUT,
        });
      },
    });

  const onReportClick = useCallback(() => {
    void refetchTaxReportUrl().then((res) => {
      if (res.data?.data.url) {
        navigateToUrl(res.data.data.url);
      }
    });
  }, [refetchTaxReportUrl]);

  const isDisabled = useMemo(() => {
    const reportStatus = getReportStatus(report);
    return isDownloadReportButtonDisabled({ reportStatus });
  }, [getReportStatus, report]);

  return {
    onReportClick,
    isLoading,
    isDisabled,
  };
};
