import { formatTaxId, isDefined } from "@taxbit-dashboard/commons";
import { ActionSnippet } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { ReportingProfileRowData } from "./useGetReportingProfile";
import ValueList from "./ValueList";
import { accountTypeMap } from "../../../../api/account/accountApiTypes";
import { taxClassificationMap } from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import maskData from "../../../../utils/maskData";
import maskTaxId from "../../../../utils/maskTaxId";
import useFormatUsdValue from "../../../../utils/useFormatUsdValue";
import BadgeWithoutLabelTransformation from "../../../BadgeWithoutLabelTransformation";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";
import KycBadge from "../KycBadge";
import TinBadge from "../TinBadge";
import VatBadge from "../VatBadge";

export enum ReportingProfileRowTrackingId {
  AccountId = "payee-information-row-account-id",
  AccountExternalId = "payee-information-row-account-external-id",
  FilerId = "payee-information-row-filer-id",
  AccountOwnerId = "payee-information-row-account-owner-id",
  AccountOwnerExternalId = "payee-information-row-account-owner-external-id",
  ResidencyTin = "payee-information-row-residency-tin",
  VatId = "payee-information-row-vat-id",
  BusinessRegistrationNumber = "payee-information-row-business-registration-number",
  FinancialAccountId = "payee-information-row-financial-account-id",
}

type Props = {
  shouldUnmaskValue: boolean;
  globalReportingProfile?: ReportingProfileRowData;
  usReportingProfile?: ReportingProfileRowData;
};

export const useReportingProfileRows = (props: Props) => {
  const { shouldUnmaskValue, globalReportingProfile, usReportingProfile } =
    props;

  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();

  return useMemo(() => {
    const buildReportingProfileRows = (
      reportingProfile?: ReportingProfileRowData
    ) => {
      if (!reportingProfile) {
        return undefined;
      }

      const birthCountry = getIsoCountryName(
        reportingProfile.profileData.birthCountry
      );

      const vatCountry = getIsoCountryName(
        reportingProfile.profileData.vatCountry
      );

      const businessRegistrationCountry = getIsoCountryName(
        reportingProfile.profileData.businessRegistrationCountry
      );

      const reportingProfileRows = {
        key: "reporting-profile",
        rows: [
          {
            label: "Name",
            content: reportingProfile.profileData.name,
            key: "name",
          },
          ...(reportingProfile.profileType === "US" &&
          (reportingProfile.profileData.dbaName ||
            reportingProfile.profileData.referenceNumbers)
            ? [
                {
                  label: "Doing Business As (Ref #)",
                  content: `${reportingProfile.profileData.dbaName ?? ""} (${
                    reportingProfile.profileData.referenceNumbers ?? ""
                  })`,
                  key: "dba-name",
                },
              ]
            : []),
          {
            label: "Email",
            content: reportingProfile.profileData.email,
            key: "email",
          },
          ...(reportingProfile.profileType === "US"
            ? [
                {
                  label: "Tax Documentation Type",
                  content: reportingProfile.taxDocumentationType,
                  key: "tax-documentation-type",
                },
                {
                  label: "Tax Documentation Status",
                  content:
                    reportingProfile.isTaxDocumentationValid === true ? (
                      <KycBadge taxStatus="VALID" />
                    ) : reportingProfile.isTaxDocumentationValid === false ? (
                      <KycBadge taxStatus="INVALID" />
                    ) : (
                      COSMIC_VALUE_PLACEHOLDER
                    ),
                  key: "tax-documentation-status",
                },
              ]
            : []),
          {
            label: "Tax Classification",
            content: reportingProfile.profileData.taxClassification
              ? taxClassificationMap[
                  reportingProfile.profileData.taxClassification
                ]
              : COSMIC_VALUE_PLACEHOLDER,
            key: "tax-classification",
          },
          ...(reportingProfile.profileData.taxClassification === "INDIVIDUAL"
            ? [
                {
                  label: "Date of Birth",
                  content: reportingProfile.profileData.birthDate
                    ? formatDateTime({
                        date: reportingProfile.profileData.birthDate,
                      })
                    : COSMIC_VALUE_PLACEHOLDER,
                  key: "birth-date",
                },
                ...(reportingProfile.profileType === "GLOBAL"
                  ? [
                      {
                        label: "Birth City",
                        content: reportingProfile.profileData.birthCity,
                        key: "birth-city",
                      },
                      {
                        label: "Birth Country",
                        content:
                          birthCountry === "" ? (
                            <InvalidCountry />
                          ) : (
                            birthCountry
                          ),
                        key: "birth-country",
                      },
                    ]
                  : []),
              ]
            : []),
          {
            label: "Address",
            content: reportingProfile.profileData.address ? (
              <AccountAddress address={reportingProfile.profileData.address} />
            ) : (
              COSMIC_VALUE_PLACEHOLDER
            ),
            key: "address",
          },
          ...(reportingProfile.profileData.mailingAddressIsDifferent &&
          reportingProfile.profileData.mailingAddress
            ? [
                {
                  label: "Mailing Address",
                  content: (
                    <AccountAddress
                      address={reportingProfile.profileData.mailingAddress}
                    />
                  ),
                  key: "mailing-address",
                },
              ]
            : []),
          ...(isDefined(reportingProfile.profileData.isTaxExempt)
            ? [
                {
                  label: "Is Tax Exempt",
                  content: formatBoolean(
                    reportingProfile.profileData.isTaxExempt
                  ),
                  key: "is-tax-exempt",
                },
              ]
            : []),
          ...(reportingProfile.profileType === "US"
            ? [
                ...(reportingProfile.profileData.exemptFatcaCode
                  ? [
                      {
                        label: "Exempt Fatca Code",
                        conent: reportingProfile.profileData.exemptFatcaCode,
                        key: "exempt-fatca-code",
                      },
                    ]
                  : []),
                ...(reportingProfile.profileData.exemptPayeeCode
                  ? [
                      {
                        label: "Exempt Payee Code",
                        conent: reportingProfile.profileData.exemptPayeeCode,
                        key: "exempt-payee-code",
                      },
                    ]
                  : []),
                ...(isDefined(
                  reportingProfile.profileData.hasFatcaFilingRequirement
                )
                  ? [
                      {
                        label: "Has Fatca Filing Requirement",
                        content: formatBoolean(
                          reportingProfile.profileData.hasFatcaFilingRequirement
                        ),
                        key: "has-fatca-filing-requirement",
                      },
                    ]
                  : []),
                ...(isDefined(
                  reportingProfile.profileData.isNotSubjectToBackupWithholding
                )
                  ? [
                      {
                        label: "Subject to Backup Withholding",
                        content: formatBoolean(
                          !reportingProfile.profileData
                            .isNotSubjectToBackupWithholding
                        ),
                        key: "subject-to-backup-withholding",
                      },
                    ]
                  : []),
                ...(isDefined(reportingProfile.profileData.hasSecondTinNotice)
                  ? [
                      {
                        label: "Has Second TIN Notice",
                        content: formatBoolean(
                          reportingProfile.profileData.hasSecondTinNotice
                        ),
                        key: "has-second-tin-notice",
                      },
                    ]
                  : []),
              ]
            : []),
          ...(reportingProfile.profileType === "GLOBAL"
            ? [
                ...(isDefined(businessRegistrationCountry)
                  ? [
                      {
                        label: "Business Registration Country",
                        content:
                          businessRegistrationCountry === "" ? (
                            <InvalidCountry />
                          ) : (
                            businessRegistrationCountry
                          ),
                        key: "business-registration-country",
                      },
                    ]
                  : []),
                ...(reportingProfile.profileData
                  .businessRegistrationNumberMasked
                  ? [
                      {
                        label: "Business Registration Number",
                        content: (
                          <ActionSnippet
                            contents={
                              reportingProfile.profileData
                                .businessRegistrationNumber ??
                              reportingProfile.profileData
                                .businessRegistrationNumberMasked
                            }
                            shouldMask={shouldUnmaskValue}
                            maskContents={(value) => maskData(value)}
                            trackingId={
                              ReportingProfileRowTrackingId.BusinessRegistrationNumber
                            }
                          />
                        ),
                        key: ReportingProfileRowTrackingId.BusinessRegistrationNumber,
                      },
                    ]
                  : []),
                ...(reportingProfile.profileData.financialAccountIdMasked
                  ? [
                      {
                        label: "Financial Account ID",
                        content: (
                          <ActionSnippet
                            contents={
                              reportingProfile.profileData.financialAccountId ??
                              reportingProfile.profileData
                                .financialAccountIdMasked
                            }
                            shouldMask={shouldUnmaskValue}
                            maskContents={(value) => maskData(value)}
                            trackingId={
                              ReportingProfileRowTrackingId.FinancialAccountId
                            }
                          />
                        ),
                        key: ReportingProfileRowTrackingId.FinancialAccountId,
                      },
                    ]
                  : []),
                ...(reportingProfile.profileData.financialAccountHolderName
                  ? [
                      {
                        label: "Financial Account Holder Name",
                        content:
                          reportingProfile.profileData
                            .financialAccountHolderName,
                        key: "financial-account-holder-name",
                      },
                    ]
                  : []),
              ]
            : []),
        ],
      };

      const otherDataRows = {
        key: "other-data",
        rows: [
          {
            label: "Filer ID",
            content: (
              <ActionSnippet
                contents={reportingProfile.filerId}
                shouldCopy={true}
                trackingId={ReportingProfileRowTrackingId.FilerId}
              />
            ),
            key: ReportingProfileRowTrackingId.FilerId,
          },
          {
            label: "Filer Name",
            content: reportingProfile.payerName,
            key: "filer-name",
          },
          ...(reportingProfile.profileType === "US"
            ? [
                ...(reportingProfile.accountType
                  ? [
                      {
                        label: "Account Type",
                        content: accountTypeMap[reportingProfile.accountType],
                        key: "account-type",
                      },
                    ]
                  : []),
                ...(reportingProfile.profileData.establishmentDate
                  ? [
                      {
                        label: "Establishment Date",
                        content: formatDateTime({
                          date: reportingProfile.profileData.establishmentDate,
                        }),
                        key: "establishment-date",
                      },
                    ]
                  : []),
                ...(reportingProfile.mostRecentFairMarketValue
                  ? [
                      {
                        label: "Fair Market Value",
                        content: formatUsdValue(
                          reportingProfile.mostRecentFairMarketValue
                        ),
                        key: "fair-market-value",
                      },
                    ]
                  : []),
              ]
            : []),
        ],
      };

      const vatRows = {
        key: "vat",
        rows: [
          ...(vatCountry
            ? [
                {
                  label: "VAT Country",
                  content: vatCountry === "" ? <InvalidCountry /> : vatCountry,
                  key: "vat-country",
                },
              ]
            : []),
          ...(reportingProfile.profileData.vatinNotRequired &&
          reportingProfile.profileData.vatStatus === "NOT_REQUIRED"
            ? []
            : [
                {
                  label: "VAT Number",
                  content: reportingProfile.profileData.vatinNotRequired ? (
                    <VatBadge vatValidationStatus="NOT_REQUIRED" />
                  ) : (
                    <ActionSnippet
                      contents={
                        reportingProfile.profileData.vatId ??
                        reportingProfile.profileData.vatIdMasked
                      }
                      shouldMask={shouldUnmaskValue}
                      maskContents={(value) => maskData(value)}
                      trackingId={ReportingProfileRowTrackingId.VatId}
                    />
                  ),
                  key: ReportingProfileRowTrackingId.VatId,
                },
              ]),
          ...(reportingProfile.profileData.vatStatus
            ? [
                {
                  label: "VAT Number Status",
                  content: (
                    <VatBadge
                      vatValidationStatus={
                        reportingProfile.profileData.vatStatus
                      }
                    />
                  ),
                  key: "vat-status",
                },
              ]
            : []),
        ],
      };

      const residencyAndTinRows = reportingProfile.profileData
        .residenciesAndTins
        ? reportingProfile.profileData.residenciesAndTins.flatMap(
            (residencyAndTin, index) => {
              const taxCountry = getIsoCountryName(
                residencyAndTin.taxCountryCode
              );

              const residenciesAndTinsSuffix =
                (reportingProfile.profileData.residenciesAndTins?.length ?? 0) >
                1
                  ? ` ${index + 1}`
                  : "";

              return [
                {
                  label: `Tax Country${residenciesAndTinsSuffix}`,
                  content: taxCountry === "" ? <InvalidCountry /> : taxCountry,
                  key: "tax-country",
                },
                {
                  label: `TIN${residenciesAndTinsSuffix}`,
                  content: residencyAndTin.tinNotRequired ? (
                    <BadgeWithoutLabelTransformation
                      label="Not Required"
                      variant="secondary"
                    />
                  ) : (
                    <ActionSnippet
                      contents={
                        residencyAndTin.tin
                          ? formatTaxId(
                              residencyAndTin.tin,
                              residencyAndTin.tinType
                            )
                          : residencyAndTin.maskedTin
                      }
                      shouldMask={shouldUnmaskValue}
                      maskContents={(_value) =>
                        maskTaxId(
                          residencyAndTin.tin,
                          residencyAndTin.tinType
                        ) ?? COSMIC_VALUE_PLACEHOLDER
                      }
                      trackingId={ReportingProfileRowTrackingId.ResidencyTin}
                    />
                  ),
                  key: ReportingProfileRowTrackingId.ResidencyTin,
                },
                ...(reportingProfile.profileType === "US" &&
                residencyAndTin.tinType
                  ? [
                      {
                        label: "TIN Type",
                        content: residencyAndTin.tinType,
                        key: "tin-type",
                      },
                    ]
                  : []),
                ...((residencyAndTin.taxCountryCode === "US" ||
                  taxCountry === "" ||
                  !taxCountry) &&
                reportingProfile.profileType === "US"
                  ? [
                      ...(reportingProfile.profileData.irsTinMatchResult
                        ? [
                            {
                              label: "US TIN Status",
                              content: (
                                <TinBadge
                                  tinValidationStatus={
                                    reportingProfile.profileData
                                      .irsTinMatchResult
                                  }
                                />
                              ),
                              key: "us-tin-status",
                            },
                          ]
                        : []),
                      ...(reportingProfile.profileData.irsTinMatchResultDate
                        ? [
                            {
                              label: "US TIN Verification Date",
                              content: formatDateTime({
                                date: reportingProfile.profileData
                                  .irsTinMatchResultDate,
                              }),
                              key: "us-tin-verification-date",
                            },
                          ]
                        : []),
                    ]
                  : []),
              ];
            }
          )
        : [];

      const secondaryAccountOwnersRows =
        reportingProfile.secondaryAccountOwnerNames &&
        reportingProfile.secondaryAccountOwnerNames.length > 0
          ? {
              key: "secondary-account-owners",
              rows: [
                {
                  label: "Additional Account Owners",
                  content: (
                    <ValueList
                      values={reportingProfile.secondaryAccountOwnerNames}
                    />
                  ),
                  key: "additional-account-owners",
                },
              ],
            }
          : undefined;

      return [
        reportingProfileRows,
        ...(residencyAndTinRows.length > 0
          ? [
              {
                key: "residency-and-tin",
                rows: residencyAndTinRows,
              },
            ]
          : []),
        ...(reportingProfile.profileType === "GLOBAL" ? [vatRows] : []),
        otherDataRows,
        ...(secondaryAccountOwnersRows ? [secondaryAccountOwnersRows] : []),
      ];
    };

    return {
      usProfile: buildReportingProfileRows(usReportingProfile),
      globalProfile: buildReportingProfileRows(globalReportingProfile),
    };
  }, [
    formatDateTime,
    formatBoolean,
    formatUsdValue,
    globalReportingProfile,
    usReportingProfile,
    shouldUnmaskValue,
  ]);
};
