import { AssetAmount } from "@taxbit-dashboard/rest";
import {
  Box,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Table,
  TableColumns,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useGetInventory from "./useGetInventory";
import EntityNotFound from "../../EntityNotFound";

export enum InventoryTabTrackingId {
  InventoryTable = "inventory-table",
  InventoryTabSpinner = "inventory-tab-spinner",
}

const InventoryTab = () => {
  const { isLoading, inventoryItems, isError, isAccountNotFoundError } =
    useGetInventory();

  const { formatQuantity } = useCosmicLocalizationContext();

  const columns = useMemo(
    (): TableColumns<AssetAmount> => [
      {
        key: "asset",
        label: "Asset",
        renderCell: (value) => value.name,
        sortMethod: (a, b) => (a.name ?? "").localeCompare(b.name ?? ""),
      },
      {
        key: "amount",
        label: "Quantity",
        textAlign: "right",
        renderCell: (value) => formatQuantity(value),
      },
    ],
    [formatQuantity]
  );

  return isLoading ? (
    <ContentSpinner trackingId={InventoryTabTrackingId.InventoryTabSpinner} />
  ) : isAccountNotFoundError ? (
    <Box>
      <EntityNotFound entity="Account" />
    </Box>
  ) : isError ? (
    <Box>
      <ContentErrorEmptyState entity="assets" />
    </Box>
  ) : inventoryItems?.length ? (
    <Box>
      <TrimmedBoxContent trim="all">
        <Table
          trackingId={InventoryTabTrackingId.InventoryTable}
          columns={columns}
          getRowKey={(row) => row.asset.code}
          rows={inventoryItems}
          isSortable={true}
          initialSortColumnKey="amount"
          isInitialSortDescending={true}
        />
      </TrimmedBoxContent>
    </Box>
  ) : (
    <Box>
      <ContentEntityEmptyState entity="assets" />
    </Box>
  );
};

export default InventoryTab;
