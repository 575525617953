import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTaxBitRest,
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useUserPermission,
  useDashboardFeatureFlags,
  UserPermission,
} from "@taxbit-dashboard/commons";
import {
  UpdateFileRequest,
  CreateMultipartUploadRequest,
  ConfirmIngestionRequest,
  UnexpectedResponseError,
} from "@taxbit-dashboard/rest";

import { FilesTableParams } from "./filesApiTypes";
import {
  getFileChunks,
  getTotalParts,
  shouldPollForFiles,
} from "./filesApiUtils";
import getFilesParams from "./getFilesParams";

// 10 seconds
const REFETCH_INTERVAL = 10 * 1000;

export const useGetFiles = (params: FilesTableParams) => {
  const hasReadFilesPermission = useUserPermission(UserPermission.ReadFiles);
  const { hasIngestionModuleAccess } = useDashboardFeatureFlags();

  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.Files, { ...params }],
    () => restSdk.files.get(getFilesParams(params)),
    {
      ...createQueryMetaObject(restSdk.files.buildPath()),
      // We use this hook in a top-level context provider that exists for all users,
      // so we must gate the query to only run for users who have the correct permissions.
      enabled: hasReadFilesPermission && hasIngestionModuleAccess,
      refetchInterval: (data) =>
        shouldPollForFiles(data) ? REFETCH_INTERVAL : false,
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useUpdateFile = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { fileId: string; requestData: UpdateFileRequest }) =>
      restSdk.files.file.put(params),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([DashboardQueryKey.Files]);
      },
    }
  );
};

export const useDeleteFile = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation(
    (req: { fileId: string }) => restSdk.files.file.delete(req),
    {
      onSuccess: () => queryClient.invalidateQueries([DashboardQueryKey.Files]),
    }
  );
};

export const useUploadFile = ({
  onUploadStart,
  onChunkUploaded,
  onUploadComplete,
}: {
  onUploadStart: (uploadId: string) => void;
  onChunkUploaded: () => void;
  onUploadComplete: () => void;
}) => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation<
    void,
    UnexpectedResponseError,
    {
      requestData: Omit<CreateMultipartUploadRequest, "totalParts">;
      file: File;
    },
    void
  >(async ({ requestData, file }) => {
    const totalParts = getTotalParts(file.size);

    const {
      data: { uploadId, presignedUrls },
    } = await restSdk.files.multipartUploads.post({
      requestData: {
        ...requestData,
        totalParts,
      },
    });

    onUploadStart(uploadId);

    // The new file will be returned as one of this organization's uploaded files as
    // soon as we begin the upload process, so we refresh the query to pull it into the table.
    void queryClient.invalidateQueries([DashboardQueryKey.Files]);

    try {
      const fileChunks = getFileChunks(file);

      const parts: { partId: number; eTag: string }[] = [];

      for (const chunk of fileChunks) {
        const { fileChunk, chunkNumber } = chunk;

        const { partId, presignedUrl } = presignedUrls[chunkNumber - 1] ?? {};

        if (!partId || !presignedUrl) {
          throw new Error("Missing presigned URL info for file portion");
        }

        const { eTag } = await restSdk.files.multipartUploads.put({
          presignedUrl,
          fileChunk,
        });

        if (!eTag) {
          throw new Error(
            "Attempted upload of file portion did not return eTag header"
          );
        }

        onChunkUploaded();
        parts.push({ partId, eTag });
      }

      await restSdk.files.multipartUploads.upload.end.post({
        uploadId,
        requestData: {
          parts,
        },
      });

      // Refresh the table data again to demonstrate that the uploaded file is now validating.
      await queryClient.invalidateQueries([DashboardQueryKey.Files]);

      onUploadComplete();
    } catch (e) {
      await restSdk.files.multipartUploads.upload.delete({ uploadId });

      // Refresh the table data again to remove the failed upload from the table.
      void queryClient.invalidateQueries([DashboardQueryKey.Files]);

      onUploadComplete();

      // Rethrow the above error because our upload process still failed even
      // if we were successfully able to delete the failed upload from the TaxBit system.
      throw e;
    }
  });
};

export const useRemoveUpload = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation(
    (req: { uploadId: string }) =>
      restSdk.files.multipartUploads.upload.delete(req),
    {
      onSuccess: () => queryClient.invalidateQueries([DashboardQueryKey.Files]),
    }
  );
};

export const useConfirmFileIngestion = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { fileId: string; requestData: ConfirmIngestionRequest }) =>
      restSdk.files.file.confirmIngestion.put(params),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([DashboardQueryKey.Files]);
      },
    }
  );
};

export const useGetFileUrl = ({ fileId }: { fileId: string }) => {
  const restSdk = useTaxBitRest();

  const { refetch, ...query } = useQuery(
    [DashboardQueryKey.FileUrl, { fileId }],
    () => restSdk.files.file.get({ fileId }),
    {
      ...createQueryMetaObject(restSdk.files.file.buildPath()),
      // The pre-signed URLs expire after only 60 seconds, so we will disable
      // all automatic fetching and only get a URL with the manual `refetch` function.
      enabled: false,
    }
  );

  return {
    getFileUrl: async () => unwrapPublicApiWrappedQuery(await refetch()),
    ...query,
  };
};

export const useGetFileErrorReportUrl = ({ fileId }: { fileId: string }) => {
  const restSdk = useTaxBitRest();

  const { refetch, ...query } = useQuery(
    [DashboardQueryKey.FileErrorReport, { fileId }],
    () => restSdk.files.file.errorReport.get({ fileId }),
    {
      ...createQueryMetaObject(restSdk.files.file.errorReport.buildPath()),
      // The pre-signed URLs expire after only 60 seconds, so we will disable
      // all automatic fetching and only get a URL with the manual `refetch` function.
      enabled: false,
    }
  );

  return {
    getFileErrorReportUrl: async () =>
      unwrapPublicApiWrappedQuery(await refetch()),
    ...query,
  };
};
