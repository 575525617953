import {
  hasDefinedValues,
  renderExcludedSpanDefaultToCosmicPlaceholder,
} from "@taxbit-dashboard/commons";
import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { ActionSnippet } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { byIso } from "country-code-lookup";
import { useMemo } from "react";

import {
  AccountOwnerInformation,
  accountTypeMap,
} from "../../../../../api/account/accountApiTypes";
import { taxClassificationMap } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import maskData from "../../../../../utils/maskData";
import useFormatUsdValue from "../../../../../utils/useFormatUsdValue";
import AccountAddress from "../../AccountAddress";

export const GeneralInformationRowTrackingId = {
  AccountOwnerId: "account-owner-id",
  AccountId: "account-id",
  FinancialAccountId: "financial-account-id",
  BusinessRegistrationNumber: "business-registration-number",
};

const useGeneralInformationRows = ({
  generalInformation,
  shouldUnmaskValue,
}: {
  generalInformation?: AccountOwnerInformation;
  shouldUnmaskValue: boolean;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();

  return useMemo(() => {
    if (!generalInformation) {
      return undefined;
    }

    const topSectionRows = {
      key: "top-section",
      rows: [
        {
          label: "Name",
          content: renderExcludedSpanDefaultToCosmicPlaceholder(
            generalInformation.accountOwner.pii.name
          ),
          key: "name",
        },
        ...(generalInformation.accountOwner.pii.address &&
        hasDefinedValues(generalInformation.accountOwner.pii.address)
          ? [
              {
                label: "Address",
                content: (
                  <AccountAddress
                    address={generalInformation.accountOwner.pii.address}
                  />
                ),
                key: "address",
              },
            ]
          : []),
        {
          label: "Account Owner ID",
          content: (
            <ActionSnippet
              contents={generalInformation.accountOwner.externalId}
              shouldCopy={true}
              trackingId={GeneralInformationRowTrackingId.AccountOwnerId}
            />
          ),
          key: "account-owner-id",
        },
        ...(generalInformation.taxClassification
          ? [
              {
                label: "Tax Classification",
                content:
                  taxClassificationMap[generalInformation.taxClassification] ??
                  "Other",
                key: "tax-classification",
              },
            ]
          : []),
        ...(generalInformation.documentType === KycTaxDocumentType.Dps &&
        generalInformation.accountOwner.pii.birthCity
          ? [
              {
                label: "City of Birth",
                content: renderExcludedSpanDefaultToCosmicPlaceholder(
                  generalInformation.accountOwner.pii.birthCity
                ),
                key: "city-of-birth",
              },
            ]
          : []),
        ...(generalInformation.documentType === KycTaxDocumentType.Dps &&
        generalInformation.accountOwner.pii.birthCountry
          ? [
              {
                label: "Country of Birth",
                content: renderExcludedSpanDefaultToCosmicPlaceholder(
                  byIso(generalInformation.accountOwner.pii.birthCountry)
                    ?.country
                ),
                key: "country-of-birth",
              },
            ]
          : []),
        ...(generalInformation.accountOwner.pii.birthDate
          ? [
              {
                label: "Date of Birth",
                content: formatDateTime({
                  date: generalInformation.accountOwner.pii.birthDate,
                }),
                key: "date-of-birth",
              },
            ]
          : []),
      ],
    };

    const secondSectionRows = {
      key: "second-section",
      rows: [
        {
          label: "Account ID",
          content: (
            <ActionSnippet
              contents={generalInformation.accountOwner.accounts[0]?.externalId}
              shouldCopy={true}
              trackingId={GeneralInformationRowTrackingId.AccountId}
            />
          ),
          key: "account-id",
        },
        ...(generalInformation.accountOwner.accounts[0]?.accountType
          ? [
              {
                label: "Account Type",
                content:
                  accountTypeMap[
                    generalInformation.accountOwner.accounts[0].accountType
                  ],
                key: "account-type",
              },
            ]
          : []),
        ...(generalInformation.accountOwner.accounts[0]?.establishmentDate
          ? [
              {
                label: "Establishment Date",
                content: formatDateTime({
                  date: generalInformation.accountOwner.accounts[0]
                    .establishmentDate,
                }),
                key: "establishment-date",
              },
            ]
          : []),
        ...(generalInformation.fairMarketValue
          ? [
              {
                label: "Fair Market Value",
                content: formatUsdValue(generalInformation.fairMarketValue),
                key: "fair-market-value",
              },
            ]
          : []),
        ...(generalInformation.documentType === KycTaxDocumentType.Dps
          ? [
              ...(generalInformation.accountOwner.pii
                .financialAccountIdMasked &&
              generalInformation.accountOwner.pii.financialAccountIdMasked !==
                COSMIC_VALUE_PLACEHOLDER
                ? [
                    {
                      label: "Bank Account Number",
                      content: (
                        <ActionSnippet
                          contents={
                            generalInformation.accountOwner.pii
                              .financialAccountId ??
                            generalInformation.accountOwner.pii
                              .financialAccountIdMasked
                          }
                          shouldMask={shouldUnmaskValue}
                          maskContents={(value) => maskData(value)}
                          trackingId={
                            GeneralInformationRowTrackingId.FinancialAccountId
                          }
                        />
                      ),
                      key: "bank-account-number",
                    },
                  ]
                : []),
              ...(generalInformation.accountOwner.pii.financialAccountHolderName
                ? [
                    {
                      label: "Bank Account Holder",
                      content: renderExcludedSpanDefaultToCosmicPlaceholder(
                        generalInformation.accountOwner.pii
                          .financialAccountHolderName
                      ),
                      key: "bank-account-holder",
                    },
                  ]
                : []),
              ...(generalInformation.accountOwner.pii
                .businessRegistrationNumberMasked &&
              generalInformation.accountOwner.pii
                .businessRegistrationNumberMasked !== COSMIC_VALUE_PLACEHOLDER
                ? [
                    {
                      label: "Business Registration Number",
                      content: (
                        <ActionSnippet
                          contents={
                            generalInformation.accountOwner.pii
                              .businessRegistrationNumber ??
                            generalInformation.accountOwner.pii
                              .businessRegistrationNumberMasked
                          }
                          shouldMask={shouldUnmaskValue}
                          maskContents={(value) => maskData(value)}
                          trackingId={
                            GeneralInformationRowTrackingId.BusinessRegistrationNumber
                          }
                        />
                      ),
                      key: "business-registration-number",
                    },
                  ]
                : []),
              ...(generalInformation.accountOwner.pii
                .businessRegistrationCountry
                ? [
                    {
                      label: "BRN Country",
                      content: renderExcludedSpanDefaultToCosmicPlaceholder(
                        byIso(
                          generalInformation.accountOwner.pii
                            .businessRegistrationCountry
                        )?.country
                      ),
                      key: "brn-country",
                    },
                  ]
                : []),
            ]
          : []),
        ...(generalInformation.payerName
          ? [
              {
                label: "Filer",
                content: renderExcludedSpanDefaultToCosmicPlaceholder(
                  generalInformation.payerName
                ),
                key: "filer",
              },
            ]
          : []),
      ],
    };

    const thirdSectionRows = {
      key: "third-section",
      rows: [
        {
          label: "Created Date",
          content: formatDateTime({
            date: generalInformation.accountOwner.accounts[0]?.dateCreated,
            format: "DateTime",
          }),
          key: "created-date",
        },
        {
          label: "Modified Date",
          content: formatDateTime({
            date: generalInformation.accountOwner.accounts[0]?.dateModified,
            format: "DateTime",
          }),
          key: "modified-date",
        },
      ],
    };

    const bottomSectionRows = {
      key: "bottom-section",
      rows: [
        {
          label: "Physical Mailing",
          content: formatBoolean(
            generalInformation.accountOwner.prefersPhysicalMail ?? false
          ),
          key: "physical-mailing",
        },
      ],
    };

    return [
      topSectionRows,
      secondSectionRows,
      thirdSectionRows,
      bottomSectionRows,
    ];
  }, [
    formatDateTime,
    formatUsdValue,
    generalInformation,
    formatBoolean,
    shouldUnmaskValue,
  ]);
};

export default useGeneralInformationRows;
