import { isDefined } from "@taxbit-dashboard/commons";
import { Badge } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  Dac7Details,
  kycTaxDocumentationDac7StatusToBadgePropsMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";

const useDac7InterviewDetailsRows = ({
  dac7Details,
}: {
  dac7Details?: Dac7Details;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!dac7Details) {
      return undefined;
    }

    const rows = {
      key: "dps-interview",
      rows: [
        {
          label: "Interview Status",
          content: (
            <Badge
              {...kycTaxDocumentationDac7StatusToBadgePropsMap[
                dac7Details.interviewStatus
              ]}
            />
          ),
          key: "interview-status",
        },
        ...(dac7Details.name
          ? [
              {
                label: "Name",
                content: dac7Details.name,
                key: "name",
              },
            ]
          : []),
        ...(isDefined(dac7Details.isIndividual)
          ? [
              {
                label: "Classification",
                content: dac7Details.isIndividual ? "Individual" : "Entity",
                key: "classification",
              },
            ]
          : []),
        ...(isDefined(dac7Details.isEuResident)
          ? [
              {
                label: "EU Resident",
                content: formatBoolean(dac7Details.isEuResident),
                key: "eu-resident",
              },
            ]
          : []),
        ...(dac7Details.submissionDate
          ? [
              {
                label: "Submission Date",
                content: formatDateTime({ date: dac7Details.submissionDate }),
                key: "submission-date",
              },
            ]
          : []),
        ...(dac7Details.expirationDate
          ? [
              {
                label: "Expiration Date",
                content: formatDateTime({ date: dac7Details.expirationDate }),
                key: "expiration-date",
              },
            ]
          : []),
      ],
    };

    return [rows];
  }, [dac7Details, formatDateTime, formatBoolean]);
};

export default useDac7InterviewDetailsRows;
