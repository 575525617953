import { AppOutlet } from "@taxbit-dashboard/commons";

import {
  navigationItem as accountsNavigationItem,
  route as accountsRoute,
} from "./src/pages/accounts/route";
import {
  route as irRoute,
  navigationItem as irNavigationItem,
} from "./src/pages/information-reporting/route";
import {
  route as ingestionRoute,
  navigationItem as ingestionNavigationItem,
} from "./src/pages/ingestion/route";
import {
  navigationItem as overviewNavigationItem,
  route as overviewRoute,
} from "./src/pages/overview/route";
import {
  navigationItem as reportsNavigationItem,
  route as reportsRoute,
} from "./src/pages/reports/route";

export const route = {
  element: (
    <AppOutlet
      sections={[
        {
          items: [
            overviewNavigationItem,
            accountsNavigationItem,
            ingestionNavigationItem,
            irNavigationItem,
            reportsNavigationItem,
          ],
        },
      ]}
    />
  ),
  children: [
    overviewRoute,
    accountsRoute,
    reportsRoute,
    irRoute,
    ingestionRoute,
  ],
};

export { DeleteFileUploaderProvider } from "./src/pages/ingestion/context/delete/DeleteFileUploaderContext";
export { IngestFileUploaderProvider } from "./src/pages/ingestion/context/ingest/IngestFileUploaderContext";
