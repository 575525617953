import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";
import { dashboardFormTypeSchema } from "./shared/dashboardFormType";

export enum FileAction {
  Ingest = "INGEST",
  Delete = "DELETE",
}

export enum FileType {
  Transactions = "TRANSACTIONS",
  Accounts = "ACCOUNTS",
  Forms = "FORM_DATA",
  DeleteTransactions = "DELETE_TRANSACTIONS",
  DeleteAccounts = "DELETE_ACCOUNTS",
  DeleteAccountOwners = "DELETE_ACCOUNT_OWNERS",
  DeleteForms = "DELETE_FORM_DATA",
}

export enum AccountsFileType {
  AccountOwner = "ACCOUNT_OWNER",
  AccountOwnerIra = "ACCOUNT_OWNER_IRA",
  AccountOwnerEu = "ACCOUNT_OWNER_EU",
}

export enum FileProcessingStatus {
  Uploading = "UPLOADING",
  Validating = "VALIDATING",
  Invalid = "INVALID",
  PendingApproval = "PENDING_APPROVAL",
  Rejected = "REJECTED",
  Ingesting = "INGESTING",
  Completed = "COMPLETED",
  ContactSupport = "CONTACT_SUPPORT",
}

const transactionsFileMetadata = z.object({
  totalTransactions: z.number(),
  failedTransactions: z.number(),
  warningTransactions: z.number().optional(),
  succeededTransactions: z.number(),
  newSucceededTransactions: z.number(),
  updatedSucceededTransactions: z.number(),
  newFailedTransactions: z.number(),
  updatedFailedTransactions: z.number(),
});

export type TransactionsFileMetadata = z.infer<typeof transactionsFileMetadata>;

const accountsFileMetadata = z.object({
  totalAccounts: z.number(),
  totalAccountOwners: z.number(),
  newAccounts: z.number(),
  newAccountOwners: z.number(),
  updatedAccounts: z.number(),
  updatedAccountOwners: z.number(),
  failedAccounts: z.number(),
  warningAccounts: z.number(),
  succeededAccounts: z.number(),
});

export type AccountsFileMetadata = z.infer<typeof accountsFileMetadata>;

const formsFileMetadata = z.object({
  totalRows: z.number(),
  succeededRows: z.number(),
  failedRows: z.number(),
  warningRows: z.number().optional(),
  updatedFormDataAccounts: z.number().optional(),
  newFormDataAccounts: z.number().optional(),
});

export type FormsFileMetadata = z.infer<typeof formsFileMetadata>;

const deleteTransactionsFileMetadata = z.object({
  totalTransactions: z.number(),
  invalidTransactions: z.number(),
  totalAccounts: z.number(),
});

export type DeleteTransactionsFileMetadata = z.infer<
  typeof deleteTransactionsFileMetadata
>;

const deleteAccountsFileMetadata = z.object({
  totalAccounts: z.number(),
  failedAccounts: z.number(),
  invalidAccounts: z.number(),
});

export type DeleteAccountsFileMetadata = z.infer<
  typeof deleteAccountsFileMetadata
>;

const deleteAccountOwnersFileMetadata = z.object({
  totalAccountOwners: z.number(),
  totalAccounts: z.number(),
  failedAccountOwners: z.number(),
  invalidAccountOwners: z.number(),
});

export type DeleteAccountOwnersFileMetadata = z.infer<
  typeof deleteAccountOwnersFileMetadata
>;

const deleteFormsFileMetadata = z.object({
  totalRows: z.number(),
  totalAccounts: z.number(),
  invalidAccounts: z.number(),
  invalidAccountIds: z.number(),
});

export type DeleteFormsFileMetadata = z.infer<typeof deleteFormsFileMetadata>;

const fileMetadataResponseSchema = z.union([
  transactionsFileMetadata,
  accountsFileMetadata,
  formsFileMetadata,
  deleteTransactionsFileMetadata,
  deleteAccountsFileMetadata,
  deleteAccountOwnersFileMetadata,
  deleteFormsFileMetadata,
]);

export const ingestFileTypes = [
  FileType.Accounts,
  FileType.Forms,
  FileType.Transactions,
];

export const deleteFileTypes = [
  FileType.DeleteAccountOwners,
  FileType.DeleteAccounts,
  FileType.DeleteForms,
  FileType.DeleteTransactions,
];

/**
 * The shape of the files returned back from the GET /files endpoint.
 * Prefixed with an API indicator to avoid a naming conflict with the
 * builtin `File` type.
 */
const filesApiFileSchema = z.object({
  fileName: z.string(),
  status: z.nativeEnum(FileProcessingStatus),
  description: z.string(),
  dateUploaded: z.string(), // Date string in ISO format
  username: z.string(),
  fileId: uuidSchema,
  fileType: z.nativeEnum(FileType),
  // Only present in files with status of `FileProcessingStatus.Uploading`.
  uploadId: z.string().optional(),
  /// Only present in files with statuses listed in `statusesWithMetadata` above.
  metadata: fileMetadataResponseSchema.optional(),
  hasErrorReport: z.boolean().optional(),
});

export type FilesApiFile = z.infer<typeof filesApiFileSchema>;

export type GetFilesParams = {
  "page[limit]"?: number;
  "page[offset]"?: number;
  "filters[type]"?: FileType[];
};

export const getFilesResponseSchema = getPublicApiSuccessSchema(
  z.array(filesApiFileSchema)
);

export const updateFileResponseSchema =
  getPublicApiSuccessSchema(filesApiFileSchema);

export const deleteFileResponseSchema =
  getPublicApiSuccessSchema(filesApiFileSchema);

export type GetFilesResponse = z.infer<typeof getFilesResponseSchema>;

export const updateFileRequestSchema = z.object({
  description: z.string(),
});

export type UpdateFileRequest = z.infer<typeof updateFileRequestSchema>;

export enum AccountsTemplateType {
  GlobalSingle = "GLOBAL_SINGLE",
  Us = "US",
  UsIra = "US_IRA",
}

/**
 * Source of truth for these values can be found in the TSS `WorkflowCode` enum here:
 * https://github.com/taxbit-private/transaction-storage-service-v2/blob/master/service/src/workflow-config/workflow.ts
 */
export enum TransactionsTemplateType {
  Adjustments = "DEFAULT_ADJUSTMENTS",
  Cesop = "CESOP",
  Dac7 = "DAC7",
  Default = "DEFAULT",
}

const fileMetadataRequestSchema = z.object({
  formTaxYear: z.string().optional(),
  formDocumentType: dashboardFormTypeSchema.optional(),
  accountsTemplateType: z.nativeEnum(AccountsTemplateType).optional(),
  transactionsTemplateType: z.nativeEnum(TransactionsTemplateType).optional(),
});

export const createMultipartUploadRequestSchema = z.object({
  fileName: z.string(),
  username: z.string(),
  fileType: z.nativeEnum(FileType),
  totalParts: z.number(),
  fileExtension: z.string(),
  description: z.string().optional(),
  metadata: fileMetadataRequestSchema.optional(),
});

export type CreateMultipartUploadRequest = z.infer<
  typeof createMultipartUploadRequestSchema
>;

export const createMultipartUploadResponseSchema = getPublicApiSuccessSchema(
  z.object({
    presignedUrls: z.array(
      z.object({
        presignedUrl: z.string(),
        partId: z.number(),
      })
    ),
    uploadId: z.string(),
    fileId: uuidSchema,
  })
);

export type UploadFileRequestParams = {
  presignedUrl: string;
  fileChunk: Blob;
};

export const abortMultipartUploadResponseSchema = getPublicApiSuccessSchema(
  z.object({
    fileName: z.string(),
    uploadId: z.string(),
    fileId: uuidSchema,
  })
);

export const endMultipartUploadRequestSchema = z.object({
  parts: z.array(
    z.object({
      partId: z.number(),
      eTag: z.string(),
    })
  ),
});

export type EndMultipartUploadRequest = z.infer<
  typeof endMultipartUploadRequestSchema
>;

export const endMultipartUploadResponseSchema = getPublicApiSuccessSchema(
  z.object({
    uploadId: z.string(),
    fileName: z.string(),
    fileId: uuidSchema,
    fileType: z.nativeEnum(FileType),
    fileMetadata: z
      .object({
        totalTransactions: z.number(),
        totalUsers: z.number(),
      })
      .optional(),
  })
);

export const confirmIngestionRequestSchema = z.object({
  isIngestionApproved: z.boolean(),
  username: z.string(),
});

export type ConfirmIngestionRequest = z.infer<
  typeof confirmIngestionRequestSchema
>;

export const confirmIngestionResponseSchema = getPublicApiSuccessSchema(
  z.object({
    fileId: uuidSchema,
  })
);

export const getFileUrlResponseSchema = getPublicApiSuccessSchema(
  z.object({
    fileId: uuidSchema,
    s3Url: z.string(),
  })
);

export const fileErrorReportResponseSchema = getPublicApiSuccessSchema(
  z.object({
    fileId: uuidSchema,
    errorReportS3Url: z.string(),
  })
);
