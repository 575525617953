import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { SelfCertQuestionnaire } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";

const useGetSelfCertQuestionnaire = () => {
  const { isLoading, taxDocumentations, shouldUnmaskValue } =
    useAccountOwnerTaxDocumentContext();

  const selfCert = taxDocumentations?.find(
    (doc) => doc.documentType === KycTaxDocumentType.SelfCertification
  );

  const selfCertQuestionnaire: SelfCertQuestionnaire | undefined =
    useMemo(() => {
      if (selfCert?.documentType !== KycTaxDocumentType.SelfCertification) {
        return undefined;
      }

      return {
        documentationStatus: "VALID",
        name: selfCert.name,
        submissionDate: selfCert.createdAt,
        isIndividual: selfCert.isIndividual,
        controllingPersons: selfCert.controllingPersons,
      };
    }, [selfCert]);

  return {
    isLoading,
    selfCertQuestionnaire,
    shouldUnmaskValue,
    selfCert,
  };
};

export default useGetSelfCertQuestionnaire;
