import { isDefined, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import {
  Box,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import Dac7InterviewDetails from "./dac7-interview-details/Dac7InterviewDetails";
import DownloadTaxDocumentation from "./download-tax-documentation/DownloadTaxDocumentation";
import FormDetails from "./form-details/FormDetails";
import SelfCertQuestionnaire from "./self-cert-questionnaire/SelfCertQuestionnaire";
import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { isWForm } from "../../../../utils/isWForm";

export enum TaxDocumentationTabTrackingId {
  TaxDocumentationTabSpinner = "tax-documentation-tab-spinner",
}

const TaxDocumentationTab = () => {
  const { hasDac7AccountAccess } = useDashboardFeatureFlags();

  const { isLoading, isError, taxDocumentations } =
    useAccountOwnerTaxDocumentContext();

  const dps = taxDocumentations?.find(
    (doc) => doc.documentType === KycTaxDocumentType.Dps
  );

  const selfCert = taxDocumentations?.find(
    (doc) => doc.documentType === KycTaxDocumentType.SelfCertification
  );

  const shouldShowDac7InterviewDetails = isDefined(dps) && hasDac7AccountAccess;
  const shouldShowSelfCertQuestionnaire = isDefined(selfCert);
  const wForm = taxDocumentations?.find((doc) => isWForm(doc));
  const shouldShowWFormsDetails = isWForm(wForm);

  const dac7InterviewDetails = shouldShowDac7InterviewDetails ? (
    <Dac7InterviewDetails />
  ) : undefined;

  const selfCertQuestionnaire = shouldShowSelfCertQuestionnaire ? (
    <SelfCertQuestionnaire />
  ) : undefined;

  const downloadTaxDocumentation = shouldShowWFormsDetails ? (
    <>
      <DownloadTaxDocumentation />
      <FormDetails />
    </>
  ) : undefined;

  return isLoading ? (
    <ContentSpinner
      trackingId={TaxDocumentationTabTrackingId.TaxDocumentationTabSpinner}
    />
  ) : isError ? (
    <Box>
      <ContentErrorEmptyState entity="tax documentation" />
    </Box>
  ) : shouldShowWFormsDetails ||
    shouldShowDac7InterviewDetails ||
    shouldShowSelfCertQuestionnaire ? (
    <>
      {downloadTaxDocumentation}
      {dac7InterviewDetails}
      {selfCertQuestionnaire}
    </>
  ) : (
    <Box>
      <ContentEntityEmptyState entity="tax documentation" />
    </Box>
  );
};

export default TaxDocumentationTab;
