import {
  useDashboardFeatureFlags,
  formatTaxId,
} from "@taxbit-dashboard/commons";
import { Payer, payerDispositionMethodLabelMap } from "@taxbit-dashboard/rest";
import { optionalAlphabeticalSort, TableColumns } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import formatPayerAddress from "./formatPayerAddress";

export const EIN_HELP_ICON_TEXT =
  "An EIN is a unique tax identification number issued by the Internal Revenue Service (IRS) to a business entity, commonly used for the purpose of reporting tax information.";

export enum FilerSetupTableTrackingId {
  HelpIcon = "filer-setup-table-ein-help-icon",
  LoadingSpinner = "filer-setup-table-loading-spinner",
  Table = "filer-setup-table",
}

const useFilerSetupTableColumns = () => {
  const { formatBoolean } = useCosmicLocalizationContext();
  const { showFilerDispositionMethod, showNonUsFilerOptions } =
    useDashboardFeatureFlags();

  return useMemo(
    (): TableColumns<Payer> => [
      {
        key: "logoUrl",
        label: "Logo",
        shouldDisableSorting: true,
        renderCell: (logoUrl) => {
          return logoUrl ? (
            <img src={logoUrl} alt="Filer Logo" />
          ) : (
            COSMIC_VALUE_PLACEHOLDER
          );
        },
      },
      {
        key: "payerName",
        label: "Name",
        sortMethod: optionalAlphabeticalSort,
        minWidth: 150,
      },
      {
        key: "ein",
        label: "US EIN",
        helpIconProps: {
          trackingId: FilerSetupTableTrackingId.HelpIcon,
          tooltipProps: {
            content: EIN_HELP_ICON_TEXT,
            isInteractive: false,
            appendTo: "body",
          },
        },
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: (ein) => formatTaxId(ein, "EIN"),
      },
      ...(showNonUsFilerOptions
        ? [
            {
              key: "tin" as const,
              label: "TIN",
              maxWidth: 150,
              shouldDisableSorting: true,
              renderCell: (tin: Payer["tin"]) => tin,
            },
          ]
        : []),
      ...(showFilerDispositionMethod
        ? [
            {
              key: "dispositionMethod" as const,
              label: "Disp. Method",
              shouldDisableSorting: true,
              renderCell: (dispositionMethod: Payer["dispositionMethod"]) =>
                payerDispositionMethodLabelMap[dispositionMethod],
            },
          ]
        : []),
      {
        key: "address",
        label: "Address",
        shouldDisableSorting: true,
        renderCell: (address) => (
          <>
            {formatPayerAddress(address).map((line) => (
              <div key={line}>{line}</div>
            ))}
          </>
        ),
        isContentFullWidth: true,
      },
      {
        key: "phone",
        label: "Phone Number",
        isContentFullWidth: true,
        shouldDisableSorting: true,
      },
      {
        key: "isDefault",
        label: "Default Filer",
        renderCell: (isDefault: boolean) => formatBoolean(isDefault),
      },
      {
        key: "payerId",
        label: "Filer ID",
        isContentFullWidth: true,
        shouldDisableSorting: true,
      },
    ],
    [formatBoolean, showFilerDispositionMethod, showNonUsFilerOptions]
  );
};

export default useFilerSetupTableColumns;
