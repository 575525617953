/**
 * Unique keys used to identify queries in the React-Query cache. These keys are often combined
 * with query parameters to key entries. For more info, see docs:
 * https://react-query.tanstack.com/guides/query-keys
 */
enum DashboardQueryKey {
  Accounts = "Accounts",
  AccountsV2 = "AccountsV2",
  AccountReportingProfiles = "AccountReportingProfiles",
  AccountOwnerCurings = "AccountOwnerCurings",
  AccountOwnerCuringArchives = "AccountOwnerCuringArchives",
  AccountOwnerDetails = "AccountOwnerDetails",
  Assets = "Assets",
  CompanyUsers = "CompanyUsers",
  CurrentCompanyUser = "CurrentCompanyUser",
  CurrentCompanyUserOrganizations = "CurrentCompanyUserOrganizations",
  EligibilityAlerts = "EligibilityAlerts",
  EligibilityCounts = "EligibilityCounts",
  EligibilityData = "EligibilityData",
  FileErrorReport = "FileErrorReport",
  Files = "Files",
  FileUrl = "FileUrl",
  FormItems = "FormItems",
  Gains = "Gains",
  GainsSummary = "GainsSummary",
  InventorySummary = "InventorySummary",
  Invitations = "Invitations",
  HydratedAccountsSearch = "HydratedAccountsSearch",
  IrForms = "IrForms",
  IrFormsAggregates = "IrFormsAggregates",
  KycTaxDocumentationDocument = "KycTaxDocumentationDocument",
  KycTaxDocumentationStatus = "KycTaxDocumentationStatus",
  Notifications = "Notifications",
  Notification = "Notification",
  OrganizationMembers = "OrganizationMembers",
  Payers = "Payers",
  Roles = "Roles",
  TaxDocumentation = "TaxDocumentation",
  TaxDocumentationStatus = "TaxDocumentationStatus",
  TaxForms = "TaxForms",
  TaxReports = "TaxReports",
  TaxReportStatus = "TaxReportStatus",
  TaxReportUrl = "TaxReportUrl",
  Transactions = "Transactions",
  TransactionsCsv = "TransactionsCsv",
  TransferInLots = "TransferInLots",
  OrganizationDevSettings = "OrganizationDevSettings",
  OverviewTransactions = "OverviewTransactions",
  OverviewFormItems = "OverviewFormItems",
  OverviewEligibility = "OverviewEligibility",
  FormItemsAvailableYears = "FormItemsAvailableYears",
}

export default DashboardQueryKey;
