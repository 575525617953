import { stringify } from "csv-stringify/browser/esm/sync";

export type CsvHeader = {
  key: string;
  header?: string;
};

export const generateCsv = <T>(columns: CsvHeader[], rows: T[]): string => {
  return stringify(rows, {
    delimiter: ",",
    columns,
    header: true,
  });
};
