import { Button, Divider, Flex } from "@taxbit-private/cosmic";
import { RhfDropdown } from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import {
  ORGANIZATION_AND_COMPANY_PICKER_FORM_ID,
  useTopNavigationActionMenuContext,
} from "./useTopNavigationActionMenu";

export enum OrganizationAndCompanyPickerTrackingId {
  BackButton = "top-navigation-company-switcher-back-button",
  CompanyPickerHelpIcon = "top-navigation-company-picker-help-icon",
  CompanyPickerDropdown = "top-navigation-company-picker-dropdown",
  OrganizationPickerDropdownHelpIcon = "top-navigation-organization-picker-help-icon",
  OrganizationPickerDropdown = "top-navigation-organization-picker-dropdown",
  SwitchOrgButton = "top-navigation-switch-org-button",
}

const OrganizationAndCompanyPicker: React.FC = () => {
  const {
    changeOrganization,
    currentOrganization,
    formMethods,
    isSwitchingOrg,
    isOrganizationDropdownVisible,
    filteredCompanies,
    filteredOrganizations,
    setSearchCompany,
    setSearchOrganization,
    searchCompany,
    searchOrganization,
    currentCompanySelectedInDropdown,
    currentOrganizationSelectedInDropdown,
    onCloseActionMenu,
  } = useTopNavigationActionMenuContext();

  return (
    <Flex direction="column" padding="m" gap="m">
      <Button
        onClick={onCloseActionMenu}
        iconName="chevron-left"
        trackingId={OrganizationAndCompanyPickerTrackingId.BackButton}
        label="Back"
        variant="anchor-primary"
        isDisabled={isSwitchingOrg}
      />
      <Divider />
      <FormProvider {...formMethods}>
        <OrgAndCompanyForm
          id={ORGANIZATION_AND_COMPANY_PICKER_FORM_ID}
          onSubmit={(e) => {
            changeOrganization(e);
          }}
        >
          <RhfDropdown
            name="company"
            options={filteredCompanies}
            label="Company"
            helpIconProps={{
              trackingId:
                OrganizationAndCompanyPickerTrackingId.CompanyPickerHelpIcon,
              tooltipProps: {
                content:
                  "A company is a high-level grouping of one or more organizations.",
                appendTo: "body",
                isInteractive: false,
              },
            }}
            getOptionKey={({ companyId }) => companyId}
            getOptionLabel={({ companyName }) => companyName}
            getOptionValue={(company) => company}
            trackingId={
              OrganizationAndCompanyPickerTrackingId.CompanyPickerDropdown
            }
            isLoading={isSwitchingOrg}
            placeholder={currentCompanySelectedInDropdown?.companyName}
            searchBarProps={{
              placeholder: "Search...",
              onTextChange: setSearchCompany,
              value: searchCompany,
            }}
            onChange={() => {
              setSearchCompany("");
            }}
            shouldAlignMenuToTargetWidth={true}
          />
          {isOrganizationDropdownVisible && (
            <>
              <Divider />
              <RhfDropdown
                name="organization"
                options={filteredOrganizations}
                label="Organization"
                helpIconProps={{
                  trackingId:
                    OrganizationAndCompanyPickerTrackingId.OrganizationPickerDropdownHelpIcon,
                  tooltipProps: {
                    content:
                      "An organization is a distinct environment that houses information",
                    appendTo: "body",
                    isInteractive: false,
                  },
                }}
                getOptionKey={({ id }) => id}
                getOptionLabel={({ name }) => name}
                getOptionValue={(organization) => organization}
                trackingId={
                  OrganizationAndCompanyPickerTrackingId.OrganizationPickerDropdown
                }
                isLoading={isSwitchingOrg}
                placeholder={currentOrganizationSelectedInDropdown.name}
                searchBarProps={{
                  placeholder: "Search...",
                  onTextChange: setSearchOrganization,
                  value: searchOrganization,
                }}
                onChange={() => {
                  setSearchOrganization("");
                }}
                shouldAlignMenuToTargetWidth={true}
              />
              <Divider />
            </>
          )}
          <Button
            label="Switch"
            iconName="shuffle"
            trackingId={OrganizationAndCompanyPickerTrackingId.SwitchOrgButton}
            type="submit"
            isDisabled={
              currentOrganization?.id ===
              currentOrganizationSelectedInDropdown.id
            }
          />
        </OrgAndCompanyForm>
      </FormProvider>
    </Flex>
  );
};

const OrgAndCompanyForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

export default OrganizationAndCompanyPicker;
