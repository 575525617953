import { isDefined } from "@taxbit-dashboard/commons";
import { ReleasedDocument } from "@taxbit-dashboard/rest";
import { Badge } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import {
  revisionTypesMap,
  taxFormStatusToBadgePropsMap,
} from "../../../../../api/tax-forms/taxFormsApiTypes";

const useGetTaxFormDetailsRows = () => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useCallback(
    ({ taxFormDetails }: { taxFormDetails: ReleasedDocument }) => {
      const rows = {
        key: "tax-form-details",
        rows: [
          {
            label: "Type",
            content: revisionTypesMap[taxFormDetails.revisionType],
            key: "type",
          },
          {
            label: "Status",
            content: (
              <Badge
                {...taxFormStatusToBadgePropsMap[taxFormDetails.revisionState]}
              />
            ),
            key: "status",
          },
          ...(isDefined(taxFormDetails.revision)
            ? [
                {
                  label: "Revision Number",
                  content: taxFormDetails.revision,
                  key: "revision-number",
                },
              ]
            : []),
          {
            label: "Date Generated",
            content: formatDateTime({ date: taxFormDetails.dateCreated }),
            key: "date-generated",
          },
          ...(isDefined(taxFormDetails.dateSent)
            ? [
                {
                  label: "Date Mailed",
                  content: formatDateTime({ date: taxFormDetails.dateSent }),
                  key: "date-mailed",
                },
              ]
            : []),
          ...(isDefined(taxFormDetails.dateEFiled)
            ? [
                {
                  label: "Date Filed",
                  content: formatDateTime({ date: taxFormDetails.dateEFiled }),
                  key: "date-filed",
                },
              ]
            : []),
        ],
      };

      return [rows];
    },
    [formatDateTime]
  );
};

export default useGetTaxFormDetailsRows;
