import { z } from "zod";

import { dateTimeStringSchema } from "./transactionApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const transferInLotItemSchema = z.object({
  costBasis: z.string(),
  createdDateTime: dateTimeStringSchema.optional(),
  acquisitionTransactionDatetime: dateTimeStringSchema.optional(),
  modifiedDateTime: dateTimeStringSchema.optional(),
  quantity: z.string().optional(),
});

export type TransferInLotItem = z.infer<typeof transferInLotItemSchema>;

export const getTransferInLotsSchema = getPublicApiSuccessSchema(
  transferInLotItemSchema.array()
);

export type GetTransferInLots = z.infer<typeof getTransferInLotsSchema>;
