import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";

import { useNotificationsPageDataContext } from "./useNotificationsPageData";

export enum NotificationsPagePaginationTrackingId {
  Pagination = "notifications-page-pagination",
}

const NotificationsPagePagination = () => {
  const { params, totalCount, setUrlParams } =
    useNotificationsPageDataContext();

  return (
    <UrlParamPaginationControls
      totalCount={totalCount}
      trackingId={NotificationsPagePaginationTrackingId.Pagination}
      urlParams={params}
      setUrlParams={setUrlParams}
    />
  );
};

export default NotificationsPagePagination;
