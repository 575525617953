import { useNavigate, useSearch } from "@tanstack/react-location";
import { useCallback, useMemo } from "react";

import {
  AccountsUrlParams,
  DEFAULT_ACCOUNTS_URL_PARAMS,
  DEFAULT_ENCODED_ACCOUNTS_URL_PARAMS,
} from "./accountsUrlParams";
import { AccountsViewType } from "./accountsView";

type FilterParams = Partial<
  Omit<AccountsUrlParams, "view" | "page" | "limit" | "sort">
>;

type PageControlParams = Partial<
  Pick<AccountsUrlParams, "page" | "limit" | "sort">
>;

// If the parsing of the params fail, it will default to the provided values
const encodedUrlWithDefaultsSchema = AccountsUrlParams.encodedSchema.catch(
  DEFAULT_ENCODED_ACCOUNTS_URL_PARAMS
);

export const useAccountsUrlParams = () => {
  const rawUrlParams = useSearch();

  const urlParams = useMemo(() => {
    const params =
      Object.keys(rawUrlParams).length === 0
        ? DEFAULT_ENCODED_ACCOUNTS_URL_PARAMS
        : rawUrlParams;
    return AccountsUrlParams.decode(encodedUrlWithDefaultsSchema.parse(params));
  }, [rawUrlParams]);

  const navigate = useNavigate();

  const setUrlParams = useCallback(
    (newParams: AccountsUrlParams) => {
      const newParamsWithDefaults = {
        ...DEFAULT_ACCOUNTS_URL_PARAMS,
        ...newParams,
      };
      navigate({
        search: AccountsUrlParams.encode(newParamsWithDefaults),
      });
    },
    [navigate]
  );

  const setFilters = useCallback(
    (updater: FilterParams | ((current: FilterParams) => FilterParams)) => {
      if (typeof updater === "function") {
        const newParams = updater(urlParams);
        setUrlParams({
          ...urlParams,
          ...newParams,
          page: 1,
        });
        return;
      }

      const newParams = {
        ...urlParams,
        ...updater,
        page: 1,
      };
      setUrlParams(newParams);
    },
    [setUrlParams, urlParams]
  );

  const clearFilters = useCallback(() => {
    const { view, sort, page, limit } = urlParams;
    setUrlParams({
      view,
      sort,
      page,
      limit,
    });
  }, [setUrlParams, urlParams]);

  const setPageControls = useCallback(
    (params: PageControlParams) => {
      setUrlParams({
        ...urlParams,
        ...params,
      });
    },
    [urlParams, setUrlParams]
  );

  const setView = useCallback(
    (view: AccountsViewType) => {
      setUrlParams({
        ...urlParams,
        view,
        tinStatuses: view === "us" ? urlParams.tinStatuses : undefined,
        vatStatuses: view === "global" ? urlParams.vatStatuses : undefined,
      });
    },
    [urlParams, setUrlParams]
  );

  return {
    urlParams,
    setFilters,
    clearFilters,
    setPageControls,
    setView,
  };
};
