import { Alert } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";

import { useEligibilityDataContext } from "../context/useEligibilityData";

export const PREVIOUSLY_ELIGIBLE_USERS_ALERT_TRACKING_ID =
  "irEligibleUsersPreviouslyEligibleAlert";

const PreviouslyEligibleUsersAlert = () => {
  const { eligibilityCounts } = useEligibilityDataContext();
  const [isWarningPresent, setIsWarningPresent] = useState(
    !!eligibilityCounts?.ineligible.hasFormGenerated
  );

  useEffect(() => {
    setIsWarningPresent(!!eligibilityCounts?.ineligible.hasFormGenerated);
  }, [eligibilityCounts]);

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const count = useMemo(
    () => eligibilityCounts?.ineligible.hasFormGenerated ?? 0,
    [eligibilityCounts?.ineligible.hasFormGenerated]
  );

  const warningMessage = useMemo(
    () =>
      `A form has been generated for ${formatWholeQuantity(count)} ${pluralize("account", count)} who ${pluralize(
        "is",
        count
      )} no longer eligible.`,
    [count, formatWholeQuantity]
  );

  if (isWarningPresent) {
    return (
      <Alert
        onClose={() => setIsWarningPresent(false)}
        variant="warning"
        trackingId={PREVIOUSLY_ELIGIBLE_USERS_ALERT_TRACKING_ID}
      >
        {warningMessage}
      </Alert>
    );
  } else {
    return undefined;
  }
};

export default PreviouslyEligibleUsersAlert;
