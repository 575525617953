import { isDefined } from "@taxbit-dashboard/commons";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  SelfCertQuestionnaire,
  controllingPersonRoleMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import KycBadge from "../../KycBadge";

const useSelfCertQuestionnaireRows = ({
  selfCertQuestionnaire,
}: {
  selfCertQuestionnaire?: SelfCertQuestionnaire;
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();
  return useMemo(() => {
    if (!selfCertQuestionnaire) {
      return undefined;
    }

    const rows = {
      key: "rows",
      rows: [
        {
          label: "Documentation Status",
          content: <KycBadge taxStatus="VALID" />,
          key: "documentation-status",
        },
        {
          label: "Name",
          content: selfCertQuestionnaire.name,
          key: "name",
        },
        {
          label: "Submission Date",
          content: formatDateTime({
            date: selfCertQuestionnaire.submissionDate,
          }),
          key: "submission-date",
        },
        {
          label: "Classification",
          content: isDefined(selfCertQuestionnaire.isIndividual)
            ? selfCertQuestionnaire.isIndividual
              ? "Individual"
              : "Entity"
            : undefined,
          key: "classification",
        },
        ...(selfCertQuestionnaire.controllingPersons
          ? selfCertQuestionnaire.controllingPersons.flatMap(
              ({ name, role }, index) => [
                {
                  label: `Controlling Person ${index + 1} Name`,
                  content: name,
                  key: `controlling-person-${index}-name`,
                },
                {
                  label: `Controlling Person ${index + 1} Role`,
                  content: role ? controllingPersonRoleMap[role] : undefined,
                  key: `controlling-person-${index}-role`,
                },
              ]
            )
          : []),
      ],
    };

    return [rows];
  }, [selfCertQuestionnaire, formatDateTime]);
};

export default useSelfCertQuestionnaireRows;
