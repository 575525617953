import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";

import { useCompanyUserManagementDataContext } from "../context/useCompanyUserManagementData";

export enum CompanyUserManagementTablePaginationTrackingIds {
  Pagination = "companyUserManagementTablePagination",
}

const CompanyUserManagementTablePagination = () => {
  const { setPaginationParams, urlParams, shouldDisableControls, totalCount } =
    useCompanyUserManagementDataContext();

  return (
    <UrlParamPaginationControls
      isDisabled={shouldDisableControls}
      totalCount={totalCount}
      trackingId={CompanyUserManagementTablePaginationTrackingIds.Pagination}
      urlParams={urlParams}
      setUrlParams={setPaginationParams}
    />
  );
};

export default CompanyUserManagementTablePagination;
