import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { TaxReportType, GetTaxReportStatus } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import useMapTaxReports from "./useMapTaxReports";
import { useGetTaxReports } from "../../../api/taxReportsApi";
import { TaxReport } from "../taxReportTypes";

const sortTaxReports = (reports: TaxReport[] | undefined) => {
  if (!reports) {
    return [];
  }

  const taxReportsCustomSortOrder: Record<TaxReportType, number> = {
    [TaxReportType.AccountIdReport]: 1,
    [TaxReportType.TransactionIdReport]: 2,
    [TaxReportType.FormDataReport]: 3,
    [TaxReportType.ReconciliationReport]: 4,
    [TaxReportType.TransactionCountsReport]: 5,
    [TaxReportType.NegativeAssetBalanceReport]: 6,
    [TaxReportType.TaxDocReport]: 7,
    [TaxReportType.TaxDocActionNeeded]: 8,
    [TaxReportType.TinReport]: 9,
    [TaxReportType.TinActionNeeded]: 10,
    [TaxReportType.VatReport]: 11,
    [TaxReportType.VatActionNeeded]: 12,
    [TaxReportType.FormsStatusReport]: 14,
  };

  return reports.sort(
    (a, b) =>
      taxReportsCustomSortOrder[a.key] - taxReportsCustomSortOrder[b.key]
  );
};

const useGetAllReports = (statuses?: GetTaxReportStatus[]) => {
  const {
    hasTaxDocumentationAccess,
    hasDac7AccountAccess,
    hasAccountIdReportAccess,
    hasNegativeAssetBalanceReportAccess,
    hasFormsStatusCannedReportAccess,
    hasTransactionCountsCannedReportAccess,
    hasFormDataCannedReportAccess,
  } = useDashboardFeatureFlags();

  const {
    isLoading,
    isError,
    data: taxReports,
  } = useGetTaxReports([
    TaxReportType.TinReport,
    TaxReportType.TinActionNeeded,
    TaxReportType.TransactionIdReport,
    TaxReportType.ReconciliationReport,
    ...(hasAccountIdReportAccess ? [TaxReportType.AccountIdReport] : []),
    ...(hasNegativeAssetBalanceReportAccess
      ? [TaxReportType.NegativeAssetBalanceReport]
      : []),
    ...(hasFormsStatusCannedReportAccess
      ? [TaxReportType.FormsStatusReport]
      : []),
    ...(hasFormDataCannedReportAccess ? [TaxReportType.FormDataReport] : []),
    ...(hasTransactionCountsCannedReportAccess
      ? [TaxReportType.TransactionCountsReport]
      : []),
    ...(hasTaxDocumentationAccess
      ? [TaxReportType.TaxDocReport, TaxReportType.TaxDocActionNeeded]
      : []),
    ...(hasDac7AccountAccess
      ? [TaxReportType.VatReport, TaxReportType.VatActionNeeded]
      : []),
  ]);

  const mapTaxReports = useMapTaxReports();

  const mappedTaxReports = useMemo(
    () => mapTaxReports(taxReports, statuses),
    [taxReports, statuses, mapTaxReports]
  );

  const reports = useMemo(
    () => sortTaxReports(mappedTaxReports),
    [mappedTaxReports]
  );

  return {
    isError,
    isLoading,
    reports,
  };
};

export default useGetAllReports;
