import { Flex, Table } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import ReportDateWithErrorIcon from "./ReportDateWithErrorIcon";
import DownloadReportButton from "../download-report-button/DownloadReportButton";
import GenerateReportButton from "../generate-report-button/GenerateReportButton";
import { TaxReport } from "../taxReportTypes";
import { useReportsDataContext } from "../useReportsData";

const reportsTableColumns = [
  { key: "name", label: "Report Name" },
  { key: "type", label: "Type" },
  { key: "description", label: "Description" },
  {
    key: "date",
    label: "Last Generated",
    renderCell: (_: string, report: TaxReport) => (
      <ReportDateWithErrorIcon report={report} />
    ),
    isContentFullWidth: true,
  },
] as const;

enum ReportsTableTrackingId {
  CosmicTable = "reports-table",
}

const ReportsTable: React.FC = () => {
  const { reports } = useReportsDataContext();

  const getRowKey = useCallback((row: TaxReport) => row.key, []);

  const getRowUtilityElement = useCallback((report: TaxReport) => {
    return (
      <Flex alignItems="center">
        <Flex padding="none xs">
          <GenerateReportButton report={report} />
        </Flex>
        <Flex padding="none xs">
          <DownloadReportButton report={report} />
        </Flex>
      </Flex>
    );
  }, []);

  return (
    <Table
      trackingId={ReportsTableTrackingId.CosmicTable}
      columns={reportsTableColumns}
      getRowUtilityElement={getRowUtilityElement}
      rows={reports}
      getRowKey={getRowKey}
    />
  );
};

export default ReportsTable;
