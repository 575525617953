import { useMatch } from "@tanstack/react-location";
import {
  useNavigateBackWithFallback,
  useSavedScrollPosition,
  DashboardPrimaryTemplate,
} from "@taxbit-dashboard/commons";
import {
  AccountId,
  TransactionId,
  TransactionType,
} from "@taxbit-dashboard/rest";
import { ContentSpinner } from "@taxbit-private/cosmic";

import DisposalSummary from "./DisposalSummary";
import DispositionTable from "./DispositionTable";
import AcquisitionTable from "./table/AcquisitionTable";
import TransactionDetailsInformation from "./TransactionDetailsInformation";
import TransactionErrorContent from "./TransactionErrorContent";
import { useGetGainsSummary } from "../../../../api/gains-summary/useGetGainsSummary";
import { useGetTransaction } from "../../../../api/transactionsApi";
import calculateTransactionCostBasis from "../../../../utils/calculateTransactionCostBasis";

const dispositionTypes = new Set([
  TransactionType.Sell,
  TransactionType.Trade,
  TransactionType.Expense,
]);

// Set of transaction types that may require a disposition table if they involve a crypto fee
const dispositionFeeTypes = new Set([TransactionType.Buy]);

const acquisitionTypes = new Set([
  TransactionType.Buy,
  TransactionType.Income,
  TransactionType.Reward,
]);

// Set of transaction types that are not acquisitions but should display the acquisition table
const acquisitionAdjacentTypes = new Set([
  TransactionType.Trade,
  TransactionType.TransferIn,
]);

const getHasDispositions = (type?: TransactionType) => {
  if (!type) return false;

  // Check if the transaction `type` is one of the disposition types (e.g. Sell, Expense)
  return dispositionTypes.has(type) || dispositionFeeTypes.has(type);
};

const getHasAcqusitions = (type?: TransactionType) => {
  if (!type) return false;

  // Check if the transaction `type` is one of the acquisition types (e.g. Buy, Income)
  return acquisitionTypes.has(type) || acquisitionAdjacentTypes.has(type);
};

export enum TransactionDetailsTrackingId {
  BackBtn = "transactionDetailsBackBtn",
  LoadingSpinner = "transactionDetailsLoadingSpinner",
}

const TransactionDetails = () => {
  const navigateBackWithFallback = useNavigateBackWithFallback();

  useSavedScrollPosition({ key: "transaction-details" });

  const {
    params: { accountId, transactionId },
  } = useMatch();

  const {
    data: transactionData,
    isFetching: isTransactionLoading,
    isError,
    error,
  } = useGetTransaction(accountId as AccountId, transactionId as TransactionId);

  const hasDispositions = getHasDispositions(transactionData?.type);

  const isAcquisiton =
    transactionData?.type && acquisitionTypes.has(transactionData.type);

  const { isFetching: isAggregatedGainsLoading, data: aggregatedGains } =
    useGetGainsSummary({
      accountId: accountId as AccountId,
      transactionId: transactionId as TransactionId,
    });

  const aggregatedGain = aggregatedGains?.data[0];

  const totalCostBasis = isAcquisiton
    ? calculateTransactionCostBasis(transactionData)
    : hasDispositions
      ? aggregatedGain?.totalCost
      : undefined;

  const isLoading = isTransactionLoading || isAggregatedGainsLoading;

  const shouldShowAcquistionTable =
    getHasAcqusitions(transactionData?.type) && !isLoading;

  // We want to show the disposition table even if gainItems are empty
  const shouldShowDispositionTable = hasDispositions && !isLoading;
  const shouldShowDispositionSummary = aggregatedGain?.count && !isLoading;

  return (
    <DashboardPrimaryTemplate
      backButtonTrackingId={TransactionDetailsTrackingId.BackBtn}
      onBack={() =>
        navigateBackWithFallback(
          "transaction-details",
          `/accounts/${accountId}/transactions-tab`
        )
      }
      backButtonLabel="Back"
      title="Transaction Details"
    >
      {isLoading && (
        <ContentSpinner
          trackingId={TransactionDetailsTrackingId.LoadingSpinner}
        />
      )}
      {isError && <TransactionErrorContent error={error} />}
      {!isLoading && transactionData && (
        <TransactionDetailsInformation
          transaction={transactionData}
          costBasis={totalCostBasis}
          accountId={accountId as AccountId}
        />
      )}
      {shouldShowAcquistionTable && transactionData && (
        <AcquisitionTable transaction={transactionData} />
      )}
      {shouldShowDispositionSummary && (
        <DisposalSummary
          totalCost={totalCostBasis}
          totalProceeds={aggregatedGain.totalProceeds}
          totalGain={aggregatedGain.totalGain}
          totalDisposals={aggregatedGain.count || 0}
        />
      )}
      {shouldShowDispositionTable && <DispositionTable />}
    </DashboardPrimaryTemplate>
  );
};

export default TransactionDetails;
