import { isDefined, renderExcludedSpan } from "@taxbit-dashboard/commons";
import { KycTinVerificationStatus } from "@taxbit-dashboard/rest";
import { ActionSnippet, DenseRowGroupSection } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { byIso } from "country-code-lookup";
import { useMemo } from "react";

import { TinInformationResult } from "./useGetTinInformation";
import InvalidCountry from "../../InvalidCountry";
import TinBadge from "../../TinBadge";

export enum TinInformationRowsTrackingId {
  WformTin = "wform-tin",
  Dac7Tin = "dac7-tin",
}

const useTinInformationRows = ({
  tinInformation,
  shouldUnmaskValue,
}: {
  tinInformation?: TinInformationResult[];
  shouldUnmaskValue: boolean;
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!tinInformation) {
      return undefined;
    }

    const tinStatusesWithoutTinVerificationDate = new Set([
      "FOREIGN",
      KycTinVerificationStatus.ForeignIndicia,
      "INVALID_DATA",
      KycTinVerificationStatus.InvalidData,
    ]);

    return tinInformation.reduce<DenseRowGroupSection[][]>((acc, tinInfo) => {
      const isoCountryName = (() => {
        try {
          return tinInfo.country && byIso(tinInfo.country)?.country;
        } catch {
          return undefined;
        }
      })();

      if (
        !tinInfo.isDac7 &&
        (tinInfo.maskedTin || tinInfo.unmaskedTin || tinInfo.country)
      ) {
        return [
          ...acc,
          [
            {
              key: "w-form-tin",
              rows: [
                {
                  label: "TIN",
                  content: (
                    <ActionSnippet
                      contents={tinInfo.unmaskedTin ?? tinInfo.maskedTin}
                      shouldMask={shouldUnmaskValue}
                      maskContents={(_value) =>
                        tinInfo.maskedTin ?? COSMIC_VALUE_PLACEHOLDER
                      }
                      trackingId={TinInformationRowsTrackingId.WformTin}
                    />
                  ),
                  key: tinInfo.id,
                },
                {
                  label: "Country",
                  content: isDefined(isoCountryName) ? (
                    renderExcludedSpan(isoCountryName)
                  ) : (
                    <InvalidCountry country={tinInfo.country} />
                  ),
                  key: `${tinInfo.id}-country`,
                },
                ...(tinInfo.tinVerificationStatus || tinInfo.country === "US"
                  ? [
                      {
                        label: "US TIN Status",
                        content: (
                          <TinBadge
                            tinValidationStatus={tinInfo.tinVerificationStatus}
                          />
                        ),
                        key: "us-tin-status",
                      },
                      {
                        label: "Last Verified",
                        content:
                          isDefined(tinInfo.tinVerificationStatus) &&
                          !tinStatusesWithoutTinVerificationDate.has(
                            tinInfo.tinVerificationStatus
                          )
                            ? formatDateTime({
                                date: tinInfo.tinVerificationDate,
                              })
                            : COSMIC_VALUE_PLACEHOLDER,
                        key: "us-tin-verification-date",
                      },
                    ]
                  : []),
              ],
            },
          ],
        ];
      } else if (tinInfo.isDac7) {
        return [
          ...acc,
          [
            {
              key: "dac7-tin",
              rows: [
                {
                  label: "TIN",
                  content: tinInfo.tinNotRequired ? (
                    "Not Required"
                  ) : (
                    <ActionSnippet
                      contents={tinInfo.unmaskedTin ?? tinInfo.tin}
                      shouldMask={shouldUnmaskValue}
                      maskContents={(_value) =>
                        tinInfo.tin ?? COSMIC_VALUE_PLACEHOLDER
                      }
                      trackingId={TinInformationRowsTrackingId.Dac7Tin}
                    />
                  ),
                  key: tinInfo.id,
                },
                {
                  label: "Country",
                  content: isDefined(isoCountryName) ? (
                    renderExcludedSpan(isoCountryName)
                  ) : (
                    <InvalidCountry country={tinInfo.country} />
                  ),
                  key: `${tinInfo.id}-country`,
                },
              ],
            },
          ],
        ];
      }

      return acc;
    }, []);
  }, [formatDateTime, tinInformation, shouldUnmaskValue]);
};

export default useTinInformationRows;
