import { navigateToUrl } from "@taxbit-dashboard/commons";
import { FileAction, FileType } from "@taxbit-dashboard/rest";
import { Body, Button } from "@taxbit-private/cosmic";
import { useWatch } from "react-hook-form";

import { getTemplateTypeForFile } from "../../../../api/files/filesApiUtils";
import { FileUploaderFormFields } from "../../context/fileUploaderFormTypes";
import useFileUploaderContext from "../../context/useFileUploaderContext";
import { getCsvGuideDownloadUrl } from "../../csvGuideData";
import { getCsvTemplateDownloadUrl } from "../../csvTemplateData";

export enum UploadDescriptionTextTrackingId {
  GuideLink = "file-uploader-guide-link",
  TemplateLink = "file-uploader-template-link",
}

type Props = {
  action: FileAction;
  selectedFileType?: FileType;
};

const UploadDescriptionText: React.FC<Props> = ({
  action,
  selectedFileType,
}) => {
  const {
    hasMultipleTemplatesForSelectedFileType,
    showAccountIngestionAltIdOption,
  } = useFileUploaderContext(action);

  const [
    fileType,
    formDocumentType,
    accountsTemplateType,
    transactionsTemplateType,
  ] = useWatch<
    FileUploaderFormFields,
    [
      "fileType",
      "formDocumentType",
      "accountsTemplateType",
      "transactionsTemplateType",
    ]
  >({
    name: [
      "fileType",
      "formDocumentType",
      "accountsTemplateType",
      "transactionsTemplateType",
    ],
  });

  const templateType = getTemplateTypeForFile({
    fileType,
    formDocumentType,
    accountsTemplateType,
    transactionsTemplateType,
  });

  const guideUrl = getCsvGuideDownloadUrl({
    fileType,
    templateType,
  });

  const templateUrl = getCsvTemplateDownloadUrl(
    {
      fileType,
      templateType,
    },
    showAccountIngestionAltIdOption
  );

  const ctaSentence = (() => {
    const getGuideElement = (label: string) => (
      <Button
        trackingId={UploadDescriptionTextTrackingId.GuideLink}
        label={label}
        isDisabled={!guideUrl}
        tooltipProps={
          guideUrl
            ? undefined
            : {
                content:
                  action === FileAction.Delete
                    ? "Coming soon"
                    : "Select a template in order to download the associated guide.",
              }
        }
        onClick={() => {
          if (guideUrl) {
            navigateToUrl(guideUrl);
          }
        }}
        variant="anchor-primary-inline"
      />
    );

    if (hasMultipleTemplatesForSelectedFileType) {
      return (
        <>
          {getGuideElement("Follow the guide")} to format your data to meet the
          standards of our template.
        </>
      );
    } else {
      return (
        <>
          <Button
            variant="anchor-primary-inline"
            trackingId={UploadDescriptionTextTrackingId.TemplateLink}
            label="Download the template"
            isDisabled={!templateUrl}
            onClick={() => {
              if (templateUrl) {
                navigateToUrl(templateUrl);
              }
            }}
          />{" "}
          or {getGuideElement("follow the guide")} to format your data to meet
          the standards of our template.
        </>
      );
    }
  })();

  const transactionsFileSizeLimitWarning = (() => {
    if (
      selectedFileType &&
      [(FileType.Transactions, FileType.DeleteTransactions)].includes(
        selectedFileType
      )
    ) {
      return (
        <>
          Transaction files must be smaller than 2GB and contain less than 10M
          transactions.
        </>
      );
    }
    return "";
  })();

  return (
    <Body>
      Before importing your file, you must format it to match the TaxBit
      engine&apos;s requirements. {ctaSentence}{" "}
      {transactionsFileSizeLimitWarning}
    </Body>
  );
};

export default UploadDescriptionText;
