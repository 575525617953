import { Button } from "@taxbit-private/cosmic";
import { useState } from "react";

import ExportEligibilityTableModal from "./ExportEligibilityTableModal";
import useEligibilityTableExport from "./useEligibilityTableExport";

export enum EligibilityTableExportTrackingIds {
  ExportButton = "irEligibilityTableExportButton",
}

type EligibilityTableExportButtonProps = {
  isAnchor?: boolean;
  label: string;
};

const EligibilityTableExportButton: React.FC<
  EligibilityTableExportButtonProps
> = ({ isAnchor = false, label }) => {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const { isLoading, onClick, isDisabled } = useEligibilityTableExport();

  const buttonProps = isAnchor
    ? ({
        variant: "anchor-primary",
      } as const)
    : ({
        size: "small",
        variant: "button-tertiary",
        iconName: "download",
        loadingText: "Exporting",
        isLoading,
      } as const);

  return (
    <>
      <Button
        {...buttonProps}
        label={label}
        onClick={() => setIsExportModalOpen(true)}
        trackingId={EligibilityTableExportTrackingIds.ExportButton}
        isDisabled={isDisabled}
      />
      {isExportModalOpen && (
        <ExportEligibilityTableModal
          isDisabled={isDisabled}
          isLoading={isLoading}
          isOpen={isExportModalOpen}
          onClose={() => setIsExportModalOpen(false)}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default EligibilityTableExportButton;
