import { z } from "zod";

import { GetAccountsParams } from "./accountsRestApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

const exportAccountsPostSchema = z.object({
  exportId: z.string(),
});

export type GetInitiateAccountsCsvParams = Omit<
  GetAccountsParams,
  "page[offset]" | "page[limit]"
>;

export enum DownloadAccountsCsvStatus {
  Finished = "FINISHED",
  Processing = "PROCESSING",
}

export const getAccountsPostExportResponseSchema = getPublicApiSuccessSchema(
  exportAccountsPostSchema
);
