import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

const maskData = (data?: string): string => {
  if (!data) {
    return COSMIC_VALUE_PLACEHOLDER;
  }

  const maskLength = Math.max(0, data.length - 4);
  return `${"*".repeat(maskLength)}${data.slice(maskLength - data.length)}`;
};

export default maskData;
