import {
  AssetAmount,
  Transaction,
  TransactionType,
} from "@taxbit-dashboard/rest";

import calculateAssetFiatValue from "./calculateAssetFiatValue";

const calculateTransactionInFiatValue = (
  transaction: Transaction
): AssetAmount | undefined => {
  if (
    transaction.type === TransactionType.TransferIn ||
    transaction.type === TransactionType.TransferOut
  ) {
    return undefined;
  }

  const transactionAsset = transaction.received?.[0]?.rates?.[0]
    ? transaction.received[0]
    : transaction.sent?.[0];
  return calculateAssetFiatValue(transactionAsset);
};

export default calculateTransactionInFiatValue;
