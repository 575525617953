const getHeaders = ({
  accessToken,
  body,
}: {
  accessToken: string;
  body: FormData | string | undefined;
}) => {
  const headers = new Headers({
    Authorization: `Bearer ${accessToken}`,
  });

  // If our request has a string body, indicate that we are sending a JSON object as that body.
  if (typeof body === "string") {
    headers.set("Content-Type", "application/json");
  }

  return headers;
};

export default getHeaders;
