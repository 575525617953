import {
  DashboardNavigationItem,
  UserPermission,
  AuthorizedView,
} from "@taxbit-dashboard/commons";

import OverviewPage from "./Overview.page";

export const navigationItem: DashboardNavigationItem = {
  href: "/overview",
  iconName: "home",
  label: "Home",
  trackingId: "sidebar-navigation-overview-anchor",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadAccounts],
} as const;

export const route = {
  path: "overview",
  children: [
    {
      path: "/",
      element: (
        <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
          <OverviewPage />
        </AuthorizedView>
      ),
    },
  ],
};
