import snakeCaseKeysHelper from "snakecase-keys";

const options = {
  parsingOptions: {
    // Ensures that nameLine2 converts to name_line_2 rather than name_line2
    splitRegexp: /([a-z])([A-Z0-9])/g,
  },
};

/**
 * Recursively snake cases keys of an object. Returns a new object.
 */
const snakeCaseKeys = (data: Parameters<typeof snakeCaseKeysHelper>[0]) => {
  return snakeCaseKeysHelper(data, options);
};

export default snakeCaseKeys;
