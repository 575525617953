import camelCaseKeysHelper from "camelcase-keys";

const options = {
  deep: true,
};

/**
 * Recursively camel cases keys of an object. Returns a new object.
 */
const camelCaseKeys = (data: Parameters<typeof camelCaseKeysHelper>[0]) => {
  return camelCaseKeysHelper(data, options);
};

export default camelCaseKeys;
