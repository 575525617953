import { zodResolver } from "@hookform/resolvers/zod";
import {
  logError,
  useDashboardStore,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  EditPersonalInfoModalTrackingId,
  EditPersonalInfoFormFields,
  editPersonalInfoFormFieldSchema,
} from "./personalInfoModalTypes";
import { useUpdateCurrentCompanyUserName } from "../../../api/companyUsersApi";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";

const useEditPersonalInfoModalForm = (onClose: () => void) => {
  const currentCompanyUser = useDefinedCurrentCompanyUser();

  const addToast = useDashboardStore((store) => store.addToast);

  const formMethods = useForm<EditPersonalInfoFormFields>({
    resolver: zodResolver(editPersonalInfoFormFieldSchema),
    defaultValues: {
      newName: "",
      oldName: currentCompanyUser.name,
    },
  });

  const { setValue } = formMethods;

  useEffect(() => {
    setValue("oldName", currentCompanyUser.name);
  }, [setValue, currentCompanyUser]);

  const { mutate: updateUserName, isLoading } =
    useUpdateCurrentCompanyUserName();

  const handleSubmit = formMethods.handleSubmit(
    (formData: EditPersonalInfoFormFields) => {
      updateUserName(formData, {
        onSuccess: () => {
          onClose();
          addToast({
            message: "Your personal information has been updated.",
            trackingId: EditPersonalInfoModalTrackingId.SuccessToast,
            variant: "primary",
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onError: (error) => {
          logError({
            message: "Error updating personal information.",
            error,
          });
          addToast({
            message:
              "Your personal information failed to update. Please try again.",
            trackingId: EditPersonalInfoModalTrackingId.ErrorToast,
            variant: "danger",
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onSettled: () => {
          onClose();
        },
      });
    }
  );

  return { formMethods, handleSubmit, isLoading };
};

export default useEditPersonalInfoModalForm;
