import isDefined from "./isDefined";

/**
 * Helper to determine if an object or array has defined values
 */
const hasDefinedValues = <T extends unknown>(
  obj: Record<string, T> | ArrayLike<T>
): boolean => {
  return Object.values(obj).some((val) => {
    return Array.isArray(val) || (!!val && val.constructor === Object)
      ? hasDefinedValues(val)
      : val !== null && isDefined(val);
  });
};

export default hasDefinedValues;
