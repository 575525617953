const getInitialsForTopNavDropdown = (name: string) => {
  if (!name) return "";

  const cleanedName = name.trim().replaceAll(/[^a-zA-Z\s-]/g, "");

  const namesArray = cleanedName.split(/[\s-]+/);
  const firstInitial = namesArray[0]?.charAt(0).toUpperCase() ?? "";
  const lastInitial =
    namesArray.length > 1 ? namesArray.at(-1)?.charAt(0).toUpperCase() : "";

  return firstInitial + (lastInitial ?? "");
};

export default getInitialsForTopNavDropdown;
