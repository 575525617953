import { zodResolver } from "@hookform/resolvers/zod";
import {
  useDashboardFeatureFlags,
  useDashboardStore,
  useUrlFilterParamsLegacy,
} from "@taxbit-dashboard/commons";
import {
  FileType,
  FilesApiFile,
  FileAction,
  ingestFileTypes,
} from "@taxbit-dashboard/rest";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { createFileUploaderFormFieldsSchema } from "./fileUploaderFormFieldsSchema";
import { FileUploaderFormFields } from "./fileUploaderFormTypes";
import useFileUploaderFormDefaults from "./useFileUploaderFormDefaults";
import useGetFileData from "./useGetFileData";
import useTemplatesFeatureFlagData from "./useTemplatesFeatureFlagData";
import validateFilesTableUrlParams from "./validateFilesTableUrlParams";
import { useGetFiles, useUploadFile } from "../../../api/files/filesApi";
import {
  fileActionFileTypesMap,
  fileTypeLabelMap,
} from "../../../api/files/filesApiTypes";
import { getTotalParts } from "../../../api/files/filesApiUtils";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import isFormsFileType from "../utils/isFormsFileType";

const useFileUploader = (action: FileAction) => {
  const [completedParts, setCompletedParts] = useState(0);
  const [currentUploadId, setCurrentUploadId] = useState<string>();

  const { getTemplatesForFileType } = useTemplatesFeatureFlagData();

  const { filteredFileTypes, defaultValues } = useFileUploaderFormDefaults({
    action,
  });

  const { shouldSkipFileHeaderValidation, showAccountIngestionAltIdOption } =
    useDashboardFeatureFlags();

  const formMethods = useForm<FileUploaderFormFields>({
    resolver: zodResolver(
      createFileUploaderFormFieldsSchema({ shouldSkipFileHeaderValidation })
    ),
    defaultValues,
  });

  const selectedFileType = formMethods.watch("fileType");
  const currentForm = formMethods.watch("formDocumentType");
  const currentYear = formMethods.watch("formTaxYear");
  const file = formMethods.watch("files")[0];

  const hasMultipleTemplatesForSelectedFileType = useMemo(() => {
    const templates = selectedFileType
      ? getTemplatesForFileType(selectedFileType)
      : [];
    return templates.length > 1;
  }, [getTemplatesForFileType, selectedFileType]);

  const hasIngestionTemplates = useMemo(() => {
    return ingestFileTypes.some(
      (fileType) => getTemplatesForFileType(fileType).length > 0
    );
  }, [getTemplatesForFileType]);

  const { urlParams, setUrlParams } = useUrlFilterParamsLegacy({
    validateParams: validateFilesTableUrlParams,
  });

  const languageForFormType =
    selectedFileType && fileTypeLabelMap[selectedFileType].toLowerCase();

  const getFileData = useGetFileData();

  const addToast = useDashboardStore((store) => store.addToast);

  const getFilesParams = {
    fileType: fileActionFileTypesMap[action],
    ...urlParams,
  };
  const {
    data: filteredFiles,
    meta,
    ...filteredQuery
  } = useGetFiles(getFilesParams);

  const setFileTypeFilter = useCallback(
    (newFileType?: FileType) =>
      setUrlParams((draft) => {
        draft.fileType = newFileType ? [newFileType] : undefined;
        draft.page = 1;
      }),
    [setUrlParams]
  );

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile({
    onUploadStart: (uploadId) => {
      setCurrentUploadId(uploadId);
      setFileTypeFilter(undefined);

      addToast({
        message: `File ${file?.name} is currently uploading. Do not close this window or navigate away from TaxBit until the upload is complete.`,
        timeoutMs: TOAST_TIMEOUT,
        trackingId: "upload-started-toast",
      });
    },
    onChunkUploaded: () => setCompletedParts((prev) => prev + 1),
    onUploadComplete: () => {
      setCompletedParts(0);
      setCurrentUploadId(undefined);
    },
  });

  const progress = useMemo(() => {
    const totalParts = getTotalParts(file?.size ?? 0);
    return file ? Math.ceil((completedParts / totalParts) * 100) : 0;
  }, [file, completedParts]);

  const isStaleUpload = useCallback(
    (upload: FilesApiFile) =>
      !!(upload.uploadId && currentUploadId !== upload.uploadId),
    [currentUploadId]
  );

  const onSubmitForm = useCallback(
    (e: React.FormEvent) => {
      void formMethods.handleSubmit(
        ({ files: [fileToUpload], ...otherFields }) => {
          if (!fileToUpload) {
            return;
          }

          const requestData = getFileData({
            file: fileToUpload,
            ...otherFields,
          });

          if (requestData) {
            uploadFile(
              {
                requestData,
                file: fileToUpload,
              },
              {
                onSuccess: () => {
                  addToast({
                    message: `File ${
                      fileToUpload.name
                    } has been successfully uploaded. We are now validating the format of your ${languageForFormType}, and then your file will be ready for approval. Before we ${action.toLowerCase()} your ${languageForFormType}, you will need to review and approve the file.`,
                    trackingId: "upload-succeeded-toast",
                    timeoutMs: TOAST_TIMEOUT,
                  });

                  // Reset all fields except for the selected file type, to allow users to upload
                  // multiple files of the same type without having to re-select the file type each time.
                  formMethods.reset({
                    ...defaultValues,
                    fileType: selectedFileType,
                  });
                },
                onError: (error) => {
                  if (error.detailedErrors) {
                    // It is possible for us to have multiple errors on a single attempted upload,
                    // but we only want to show one message to not overwhelm the user.
                    const errorText = error.detailedErrors[0]?.detail;

                    if (errorText) {
                      formMethods.setError("files", {
                        message: errorText,
                      });
                    }
                  } else {
                    addToast({
                      message: `Failed to upload ${fileToUpload.name}. Please try again later.`,
                      trackingId: "upload-failed-toast",
                      timeoutMs: TOAST_TIMEOUT,
                      variant: "danger",
                    });
                  }
                },
              }
            );
          }
        }
      )(e);
    },
    [
      action,
      addToast,
      defaultValues,
      formMethods,
      getFileData,
      languageForFormType,
      selectedFileType,
      uploadFile,
    ]
  );

  const isUploadDisabled = useMemo(() => {
    return (
      !file ||
      (selectedFileType &&
        isFormsFileType(selectedFileType) &&
        (!currentForm || !currentYear))
    );
  }, [currentForm, currentYear, file, selectedFileType]);

  return {
    files: file ? [file] : [],
    isUploadDisabled,
    isUploading,
    progress,
    isStaleUpload,
    selectedFileType,
    fileTypeFilter: urlParams.fileType,
    setFileTypeFilter,
    urlParams,
    setUrlParams,
    filteredFiles,
    totalFilteredFilesCount: meta?.page?.totalCount ?? 0,
    filteredQuery,
    formMethods,
    onSubmitForm,
    hasIngestionTemplates,
    hasMultipleTemplatesForSelectedFileType,
    filteredFileTypes,
    showAccountIngestionAltIdOption,
  };
};

export default useFileUploader;
