import { Drawer, Button } from "@taxbit-private/cosmic";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import FormsAccountIdsFilter from "./FormsAccountIdsFilter";
import FormsDateRangeFilter from "./FormsDateRangeFilter";
import FormsStatusFilter from "./FormsStatusFilter";
import FormsTypeFilter from "./FormsTypeFilter";
import useFormsFiltersDrawerForm from "./useFormsFiltersDrawerForm";

export enum FormsFiltersDrawerTrackingId {
  CloseButton = "ir-forms-filters-drawer-close-button",
  ApplyButton = "ir-forms-filters-drawer-apply-button",
  CancelButton = "ir-forms-filters-drawer-cancel-button",
  ClearAllFiltersButton = "ir-forms-filters-drawer-clear-filters-button",
}

const FILTER_DRAWER_FORM_ID = "forms-filters-drawer-form";

const FormsFiltersDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    clearAllFormFilters,
    formMethods,
    handleSubmit,
    hasFiltersApplied,
    isFormDirty,
    resetForm,
  } = useFormsFiltersDrawerForm();

  const closeAndReset = () => {
    onClose();
    resetForm();
  };

  return (
    <Drawer
      onClose={closeAndReset}
      isOpen={isOpen}
      title="Filters"
      closeButtonTrackingId={FormsFiltersDrawerTrackingId.CloseButton}
      primaryButtonProps={{
        label: "Apply",
        trackingId: FormsFiltersDrawerTrackingId.ApplyButton,
        isDisabled: !isFormDirty,
        type: "submit",
        form: FILTER_DRAWER_FORM_ID,
      }}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: FormsFiltersDrawerTrackingId.CancelButton,
        onClick: closeAndReset,
      }}
    >
      <FormProvider {...formMethods}>
        <FlexForm
          id={FILTER_DRAWER_FORM_ID}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(e, () => onClose());
          }}
        >
          <FormsDateRangeFilter />
          <FormsTypeFilter />
          <FormsStatusFilter />
          <FormsAccountIdsFilter />
          {hasFiltersApplied && (
            <NarrowedButton
              onClick={clearAllFormFilters}
              label="Clear All Filters"
              variant="anchor-primary-inline"
              trackingId={FormsFiltersDrawerTrackingId.ClearAllFiltersButton}
            />
          )}
        </FlexForm>
      </FormProvider>
    </Drawer>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

const NarrowedButton = styled(Button)`
  max-width: fit-content;
`;

export default FormsFiltersDrawer;
