import { zodResolver } from "@hookform/resolvers/zod";
import { hasDefinedValues } from "@taxbit-dashboard/commons";
import { getUtcDateStringFromBrowserDate } from "@taxbit-private/cosmic";
import { useMemo, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import {
  FilterDrawerFields,
  DEFAULT_FILTER_DRAWER_FIELDS,
} from "./filterDrawerFields";
import { useAccountsUrlParams } from "../../../useAccountsUrlParams";

export const useAccountsFilterDrawerForm = () => {
  const { urlParams, setFilters } = useAccountsUrlParams();

  const drawerFields = useMemo(
    () => FilterDrawerFields.fromUrlParams(urlParams),
    [urlParams]
  );

  const formMethods = useForm<FilterDrawerFields>({
    resolver: zodResolver(FilterDrawerFields.schema),
    defaultValues: drawerFields,
  });

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset(FilterDrawerFields.fromUrlParams(urlParams));
  }, [urlParams, formMethods]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(
        ({ dateCreated: [startDate, endDate], ...rest }) => {
          setFilters({
            ...rest,
            dateCreatedStart:
              startDate && getUtcDateStringFromBrowserDate(startDate),
            dateCreatedEnd: endDate && getUtcDateStringFromBrowserDate(endDate),
          });
        }
      )(e);
    },
    [formMethods, setFilters]
  );

  /**
   * Clears all form filters. We must use the `setValue` form methods to individually
   * mark each state as dirty, in order to not mess with RHF's state handling.
   */
  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof FilterDrawerFields;

      if (value !== DEFAULT_FILTER_DRAWER_FIELDS[typedKey]) {
        formMethods.setValue(typedKey, DEFAULT_FILTER_DRAWER_FIELDS[typedKey], {
          shouldDirty: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  const filterCount =
    drawerFields.tinStatuses.length +
    drawerFields.vatStatuses.length +
    drawerFields.taxClassifications.length +
    drawerFields.taxDocumentationTypes.length +
    drawerFields.taxCountryCodes.length +
    drawerFields.filerIds.length +
    (drawerFields.dateCreated[0] ? 1 : 0);

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(drawerFields),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
    filterCount,
  };
};
