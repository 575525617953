import { formatTaxId, isDefined } from "@taxbit-dashboard/commons";
import {
  KycTaxDocumentType,
  KycTaxDocumentation,
  KycTaxDocumentationStatus,
  KycTaxdocumentationVatStatus,
} from "@taxbit-dashboard/rest";
import { ActionSnippet, DenseRowGroup, Modal } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";

import {
  controllingPersonRoleMap,
  documentTypeMap,
  financialInstitutionTypeMap,
  foreignAccountTypeMap,
  signatureCapacityMap,
  taxClassificationMap,
  tinNotRequiredReasonMap,
  treatyClaimLimitationOnBenefitsMap,
  treatyClaimTypeOfIncomeMap,
} from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import { isWForm } from "../../../../utils/isWForm";
import maskTaxId from "../../../../utils/maskTaxId";
import BadgeWithoutLabelTransformation from "../../../BadgeWithoutLabelTransformation";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";
import TinBadge from "../TinBadge";
import VatBadge from "../VatBadge";

type TaxDocumentationDetailsModalProps = {
  shouldUnmaskValue: boolean;
  isOpen: boolean;
  onClose: () => void;
  data: KycTaxDocumentation;
  taxDocStatus?: KycTaxDocumentationStatus;
};

export enum TaxDocumentationDetailsModalTrackingId {
  CloseBtn = "tax-documentation-details-modal-close-btn",
  PrimaryBtn = "tax-documentation-details-modal-primary-btn",
  DocumentId = "tax-documentation-details-modal-document-id",
  Tin = "tax-documentation-details-modal-tin",
  Ftin = "tax-documentation-details-modal-ftin",
  Vatin = "tax-documentation-details-modal-vatin",
  FinancialAccountId = "tax-documentation-details-modal-financial-account-id",
  BusinessRegistrationNumber = "tax-documentation-details-modal-business-registration-number",
  ResidencyTin = "tax-documentation-details-modal-residency-tin",
}

const TaxDocumentationDetailsModal = ({
  isOpen,
  onClose,
  data,
  shouldUnmaskValue,
  taxDocStatus,
}: TaxDocumentationDetailsModalProps) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const isW9 = data.documentType === KycTaxDocumentType.W9;
  const tinType = isW9 ? data.tinType : undefined;

  const isW8 =
    data.documentType === KycTaxDocumentType.W8Ben ||
    data.documentType === KycTaxDocumentType.W8BenE;

  const isDps = data.documentType === KycTaxDocumentType.Dps;
  const isSelfCert = data.documentType === KycTaxDocumentType.SelfCertification;
  const isGlobal = isDps || isSelfCert;
  const w8Country = isW8 ? getIsoCountryName(data.country) : undefined;
  const vatCountry = isDps ? getIsoCountryName(data.vatinCountry) : undefined;

  const birthCountry = isGlobal
    ? getIsoCountryName(data.countryOfBirth)
    : undefined;

  const businessRegistrationCountry = isDps
    ? getIsoCountryName(data.businessRegistrationCountry)
    : undefined;

  const treatyCountry = isW8
    ? getIsoCountryName(data.treatyClaimCountry)
    : undefined;

  const countryOfIncorporation = isSelfCert
    ? getIsoCountryName(data.countryOfIncorporation)
    : undefined;

  const generalDataFields = {
    key: "general-data",
    rows: [
      { label: "Name", content: data.name, key: "name" },
      ...(isW9 && data.dbaName
        ? [
            {
              label: "Doing Business As",
              content: data.dbaName,
              key: "dba-name",
            },
          ]
        : []),
      ...(isW8 && data.referenceNumbers
        ? [
            {
              label: "Reference Numbers",
              content: data.referenceNumbers,
              key: "reference-numbers",
            },
          ]
        : []),
      ...(isWForm(data)
        ? [
            {
              label: "Tax Classification",
              content: taxClassificationMap[data.taxClassification],
              key: "tax-classification",
            },
          ]
        : []),
      ...(isW9 && data.otherTaxClassification
        ? [
            {
              label: "Other Tax Classification",
              content: data.otherTaxClassification,
              key: "other-tax-classification",
            },
          ]
        : []),
      ...(isGlobal
        ? [
            {
              label: "Classification",
              content: data.isIndividual
                ? taxClassificationMap.INDIVIDUAL
                : taxClassificationMap.ENTITY,
              key: "classification",
            },
          ]
        : []),
      ...(isSelfCert
        ? [
            {
              label: "Account Type",
              content: data.foreignAccountType
                ? foreignAccountTypeMap[data.foreignAccountType]
                : undefined,
              key: "account-type",
            },
            {
              label: "Financial Institution Type",
              content: data.financialInstitutionType
                ? financialInstitutionTypeMap[data.financialInstitutionType]
                : undefined,
              key: "financial-institution-type",
            },
            {
              label: "Country",
              content:
                countryOfIncorporation === "" ? (
                  <InvalidCountry />
                ) : (
                  countryOfIncorporation
                ),
              key: "country",
            },
          ]
        : []),
      ...(data.documentType === KycTaxDocumentType.W8Ben ||
      (isDps && data.isIndividual) ||
      (isSelfCert && data.isIndividual)
        ? [
            {
              label: "Date of Birth",
              content: formatDateTime({
                date: data.dateOfBirth,
              }),
              key: "date-of-birth",
            },
          ]
        : []),
      ...(isW8
        ? [
            {
              label: "Country",
              content: w8Country === "" ? <InvalidCountry /> : w8Country,
              key: "country",
            },
          ]
        : []),
      ...(isGlobal && data.isIndividual
        ? [
            {
              label: "Birth City",
              content: data.cityOfBirth,
              key: "birth-city",
            },
            {
              label: "Birth Country",
              content: birthCountry === "" ? <InvalidCountry /> : birthCountry,
              key: "birth-country",
            },
          ]
        : []),
      ...(isW9 || isGlobal
        ? [
            {
              label: "Address",
              content: data.address ? (
                <AccountAddress address={data.address} />
              ) : (
                COSMIC_VALUE_PLACEHOLDER
              ),
              key: "address",
            },
            ...(isSelfCert && data.mailingAddressIsDifferent
              ? [
                  {
                    label: "Mailing Address",
                    content: data.mailingAddress ? (
                      <AccountAddress address={data.mailingAddress} />
                    ) : (
                      COSMIC_VALUE_PLACEHOLDER
                    ),
                    key: "mailing-address",
                  },
                ]
              : []),
          ]
        : []),
      ...(isW8
        ? [
            {
              label: "Permanent Address",
              content: <AccountAddress address={data.permanentAddress} />,
              key: "permanent-address",
            },
            ...(JSON.stringify(data.mailingAddress) ===
            JSON.stringify(data.permanentAddress)
              ? []
              : [
                  {
                    label: "Mailing Address",
                    content: data.mailingAddress ? (
                      <AccountAddress address={data.mailingAddress} />
                    ) : (
                      COSMIC_VALUE_PLACEHOLDER
                    ),
                    key: "mailing-address",
                  },
                ]),
          ]
        : []),
      ...(isW9
        ? [
            ...(data.exemptPayeeCode
              ? [
                  {
                    label: "Exempt Payee Code",
                    content: data.exemptPayeeCode,
                    key: "exempt-payee-code",
                  },
                ]
              : []),
            ...(data.exemptFatcaCode
              ? [
                  {
                    label: "Exempt FATCA Code",
                    content: data.exemptFatcaCode,
                    key: "exempt-fatca-code",
                  },
                ]
              : []),
          ]
        : []),
      ...(isDps
        ? [
            ...(data.businessRegistrationCountry
              ? [
                  {
                    label: "Business Registration Country",
                    content:
                      businessRegistrationCountry === "" ? (
                        <InvalidCountry />
                      ) : (
                        businessRegistrationCountry
                      ),
                    key: "business-registration-country",
                  },
                ]
              : []),
            ...(data.businessRegistrationNumber
              ? [
                  {
                    label: "Business Registration Number",
                    content: (
                      <ActionSnippet
                        contents={formatTaxId(data.businessRegistrationNumber)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={
                          TaxDocumentationDetailsModalTrackingId.BusinessRegistrationNumber
                        }
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.BusinessRegistrationNumber,
                  },
                ]
              : []),
            ...(data.financialAccountIdentifier
              ? [
                  {
                    label: "Financial Account ID",
                    content: (
                      <ActionSnippet
                        contents={formatTaxId(data.financialAccountIdentifier)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={
                          TaxDocumentationDetailsModalTrackingId.FinancialAccountId
                        }
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.FinancialAccountId,
                  },
                ]
              : []),
            ...(data.financialAccountName
              ? [
                  {
                    label: "Financial Account Holder Name",
                    content: data.financialAccountName,
                    key: "financial-account-holder-name",
                  },
                ]
              : []),
          ]
        : []),
    ],
  };

  const wFormTinFields = isWForm(data)
    ? [
        {
          key: "w-form-tin",
          rows: [
            {
              label: "US TIN",
              content: (
                <ActionSnippet
                  contents={formatTaxId(data.tin, tinType)}
                  shouldMask={shouldUnmaskValue}
                  maskContents={(_value) =>
                    maskTaxId(data.tin, tinType) ?? COSMIC_VALUE_PLACEHOLDER
                  }
                  trackingId={TaxDocumentationDetailsModalTrackingId.Tin}
                />
              ),
              key: TaxDocumentationDetailsModalTrackingId.Tin,
            },
            ...(isW9
              ? [
                  {
                    label: "US TIN Type",
                    content: data.tinType,
                    key: "us-tin-type",
                  },
                  {
                    label: "US TIN Status",
                    content: (
                      <TinBadge
                        tinValidationStatus={
                          taxDocStatus?.wFormQuestionnaire?.tinStatus
                        }
                      />
                    ),
                    key: "us-tin-status",
                  },
                  {
                    label: "US TIN Verification Date",
                    content: formatDateTime({
                      date: taxDocStatus?.wFormQuestionnaire?.tinValidationDate,
                    }),
                    key: "us-tin-verification-status",
                  },
                ]
              : []),
            ...(isW8
              ? [
                  ...(taxDocStatus?.wFormQuestionnaire?.tinStatus
                    ? [
                        {
                          label: "US TIN Status",
                          content: (
                            <TinBadge
                              tinValidationStatus={
                                taxDocStatus.wFormQuestionnaire.tinStatus
                              }
                            />
                          ),
                          key: "us-tin-status",
                        },
                      ]
                    : []),
                  ...(taxDocStatus?.wFormQuestionnaire?.tinValidationDate
                    ? [
                        {
                          label: "US TIN Verification Date",
                          content: formatDateTime({
                            date: taxDocStatus.wFormQuestionnaire
                              .tinValidationDate,
                          }),
                          key: "us-tin-verification-status",
                        },
                      ]
                    : []),
                  {
                    label: "Foreign TIN",
                    content: data.ftinNotLegallyRequired ? (
                      <BadgeWithoutLabelTransformation
                        label="Not Required"
                        variant="secondary"
                      />
                    ) : (
                      <ActionSnippet
                        contents={formatTaxId(data.ftin)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={TaxDocumentationDetailsModalTrackingId.Ftin}
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.Ftin,
                  },
                ]
              : []),
          ],
        },
      ]
    : [];

  const w8TreatyFields = isW8
    ? [
        ...(data.treatyClaimCountry
          ? [
              {
                label: "Treaty Country",
                content:
                  treatyCountry === "" ? <InvalidCountry /> : treatyCountry,
                key: "treay-country",
              },
            ]
          : []),
        ...(data.documentType === KycTaxDocumentType.W8BenE &&
        data.treatyClaimLimitationOnBenefits
          ? [
              {
                label: "Limitation On Benefits Provision",
                content:
                  treatyClaimLimitationOnBenefitsMap[
                    data.treatyClaimLimitationOnBenefits
                  ],
                key: "limitation-on-benefits-provision",
              },
            ]
          : []),
        ...(data.treatyClaimTypeOfIncome
          ? [
              {
                label: "Income Type",
                content:
                  treatyClaimTypeOfIncomeMap[data.treatyClaimTypeOfIncome],
                key: "income-type",
              },
            ]
          : []),
        ...(data.treatyClaimRateOfWithholding
          ? [
              {
                label: "Rate of Withholding",
                content: `${data.treatyClaimRateOfWithholding}%`,
                key: "rate-of-withholding",
              },
            ]
          : []),
        ...(data.treatyClaimArticleParagraph
          ? [
              {
                label: "Article & Paragraph",
                content: data.treatyClaimArticleParagraph,
                key: "article-and-paragraph",
              },
            ]
          : []),
        ...(isDefined(data.treatyClaimHasAdditionalConditions)
          ? [
              {
                label: "Additional Conditions",
                content: formatBoolean(data.treatyClaimHasAdditionalConditions),
                key: "additional-conditions",
              },
            ]
          : []),
      ]
    : [];

  const vatFields = isDps
    ? [
        {
          key: "vat",
          rows: [
            ...(data.vatinCountry
              ? [
                  {
                    label: "VAT Country",
                    content:
                      vatCountry === "" ? <InvalidCountry /> : vatCountry,
                    key: "vat-country",
                  },
                ]
              : []),
            ...(data.vatinNotRequired &&
            taxDocStatus?.dpsQuestionnaire?.vatStatus ===
              KycTaxdocumentationVatStatus.NotRequired
              ? []
              : [
                  {
                    label: "VAT Number",
                    content: data.vatinNotRequired ? (
                      <BadgeWithoutLabelTransformation
                        label="Not Required"
                        variant="secondary"
                      />
                    ) : (
                      <ActionSnippet
                        contents={formatTaxId(data.vatin)}
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={
                          TaxDocumentationDetailsModalTrackingId.Vatin
                        }
                      />
                    ),
                    key: TaxDocumentationDetailsModalTrackingId.Vatin,
                  },
                ]),
            ...(taxDocStatus?.dpsQuestionnaire?.vatStatus &&
            taxDocStatus.dpsQuestionnaire.vatStatus !==
              KycTaxdocumentationVatStatus.NonEu
              ? [
                  {
                    label: "VAT Number Status",
                    content: (
                      <VatBadge
                        vatValidationStatus={
                          taxDocStatus.dpsQuestionnaire.vatStatus
                        }
                      />
                    ),
                    key: "us-tin-status",
                  },
                ]
              : []),
          ],
        },
      ]
    : [];

  const firstResidenceCountry =
    isGlobal && data.taxResidences?.[0]
      ? getIsoCountryName(data.taxResidences[0].country)
      : undefined;

  const dpsResidenceFields = isGlobal
    ? [
        {
          key: "dps-residence",
          rows: [
            {
              label: "Tax Country 1",
              content:
                firstResidenceCountry === "" ? (
                  <InvalidCountry />
                ) : (
                  firstResidenceCountry
                ),
              key: `tax-country-${firstResidenceCountry}`,
            },
            {
              label: "TIN 1",
              content: data.taxResidences ? (
                data.taxResidences[0]?.tinNotRequired ? (
                  <BadgeWithoutLabelTransformation
                    label="Not Required"
                    variant="secondary"
                  />
                ) : (
                  <ActionSnippet
                    contents={formatTaxId(data.taxResidences[0]?.tin)}
                    shouldMask={shouldUnmaskValue}
                    maskContents={(value) =>
                      maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                    }
                    trackingId={`${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${firstResidenceCountry}`}
                  />
                )
              ) : (
                COSMIC_VALUE_PLACEHOLDER
              ),
              key: `${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${firstResidenceCountry}`,
            },
            ...(data.taxResidences
              ? data.taxResidences.flatMap(
                  ({ tin, country, tinNotRequired }, index) => {
                    if (index === 0) {
                      return [];
                    }

                    const residenceCountry = getIsoCountryName(country);
                    return [
                      {
                        label: `Tax Country ${index + 1}`,
                        content:
                          residenceCountry === "" ? (
                            <InvalidCountry />
                          ) : (
                            residenceCountry
                          ),
                        key: `tax-country-${country}`,
                      },
                      {
                        label: `TIN ${index + 1}`,
                        content: tinNotRequired ? (
                          <BadgeWithoutLabelTransformation
                            label="Not Required"
                            variant="secondary"
                          />
                        ) : (
                          <ActionSnippet
                            contents={formatTaxId(tin)}
                            shouldMask={shouldUnmaskValue}
                            maskContents={(value) =>
                              maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                            }
                            trackingId={`${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}`}
                          />
                        ),
                        key: `${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}`,
                      },
                    ];
                  }
                )
              : []),
          ],
        },
      ]
    : [];

  const controllingPersonFields =
    isSelfCert && data.controllingPersons
      ? data.controllingPersons.map((person, index) => {
          const personCountryOfBirth = getIsoCountryName(person.countryOfBirth);
          return {
            key: `controlling-person-${index}`,
            rows: [
              ...(person.name
                ? [
                    {
                      label: `Controlling Person ${index + 1} Name`,
                      content: person.name,
                      key: `controlling-person-${index + 1}-name`,
                    },
                  ]
                : []),
              ...(person.role
                ? [
                    {
                      label: `Controlling Person ${index + 1} Role`,
                      content: controllingPersonRoleMap[person.role],
                      key: `controlling-person-${index + 1}-role`,
                    },
                  ]
                : []),
              ...(person.dateOfBirth
                ? [
                    {
                      label: `Controlling Person ${index + 1} Date of Birth`,
                      content: formatDateTime({
                        date: person.dateOfBirth,
                      }),
                      key: `controlling-person-${index + 1}-date-of-birth`,
                    },
                  ]
                : []),
              ...(person.cityOfBirth
                ? [
                    {
                      label: `Controlling Person ${index + 1} Birth City`,
                      content: person.cityOfBirth,
                      key: `controlling-person-${index + 1}-birth-city`,
                    },
                  ]
                : []),
              ...(personCountryOfBirth
                ? [
                    {
                      label: `Controlling Person ${index + 1} Birth Country`,
                      content:
                        personCountryOfBirth === "" ? (
                          <InvalidCountry />
                        ) : (
                          personCountryOfBirth
                        ),
                      key: `controlling-person-${index + 1}-birth-country`,
                    },
                  ]
                : []),
              ...(person.address
                ? [
                    {
                      label: `Controlling Person ${index + 1} Address`,
                      content: <AccountAddress address={person.address} />,
                      key: `controlling-person-${index + 1}-address`,
                    },
                  ]
                : []),
              ...(person.mailingAddress && person.mailingAddressIsDifferent
                ? [
                    {
                      label: `Controlling Person ${index + 1} Mailing Address`,
                      content: (
                        <AccountAddress address={person.mailingAddress} />
                      ),
                      key: `controlling-person-${index + 1}-mailing-address`,
                    },
                  ]
                : []),
              ...(person.taxResidences
                ? person.taxResidences.flatMap(
                    (
                      { tin, country, tinNotRequired, tinNotRequiredReason },
                      residenceIndex
                    ) => {
                      const residenceCountry = getIsoCountryName(country);
                      return [
                        ...(country
                          ? [
                              {
                                label: `Controlling Person ${index + 1} Tax Country ${residenceIndex + 1}`,
                                content:
                                  residenceCountry === "" ? (
                                    <InvalidCountry />
                                  ) : (
                                    residenceCountry
                                  ),
                                key: `controlling-person-${index + 1}-tax-country-${country}-${residenceIndex + 1}`,
                              },
                            ]
                          : []),
                        ...(tin || tinNotRequired
                          ? [
                              {
                                label: `Controlling Person ${index + 1} TIN ${residenceIndex + 1}`,
                                content: tinNotRequired ? (
                                  <BadgeWithoutLabelTransformation
                                    label="Not Required"
                                    variant="secondary"
                                  />
                                ) : (
                                  <ActionSnippet
                                    contents={formatTaxId(tin)}
                                    shouldMask={shouldUnmaskValue}
                                    maskContents={(value) =>
                                      maskTaxId(value) ??
                                      COSMIC_VALUE_PLACEHOLDER
                                    }
                                    trackingId={`${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}-${residenceIndex + 1}`}
                                  />
                                ),
                                key: `controlling-person-${index + 1}-tin-${residenceIndex + 1}`,
                              },
                            ]
                          : []),
                        ...(tinNotRequiredReason
                          ? [
                              {
                                label: `Controlling Person ${index + 1} Missing TIN ${residenceIndex + 1} Reason`,
                                content:
                                  tinNotRequiredReasonMap[tinNotRequiredReason],
                                key: `controlling-person-${index + 1}-missing-tin-${residenceIndex + 1}-reason`,
                              },
                            ]
                          : []),
                      ];
                    }
                  )
                : []),
            ],
          };
        })
      : [];

  const certificationFields =
    isWForm(data) || isSelfCert
      ? [
          {
            key: "certification",
            rows: [
              {
                label: "Has Certified",
                content: formatBoolean(data.hasCertified),
                key: "has-certified",
              },
              ...(isW9
                ? [
                    {
                      label: "Subject to Backup Withholding",
                      content: formatBoolean(
                        !data.isNotSubjectBackupWithholding
                      ),
                      key: "subject-to-backup-withholding",
                    },
                  ]
                : []),
              {
                label: "Signature Name",
                content: data.signature,
                key: "signature-name",
              },
              ...(isWForm(data)
                ? [
                    {
                      label: "Signature Date",
                      content: formatDateTime({
                        date: data.signatureTimestamp,
                      }),
                      key: "signature-date",
                    },
                  ]
                : []),
              ...(isSelfCert
                ? [
                    {
                      label: "Signature Date",
                      content: formatDateTime({
                        date: data.signatureDate,
                      }),
                      key: "signature-date",
                    },
                    {
                      label: "Signature Capacity",
                      content: data.signatureCapacity
                        ? signatureCapacityMap[data.signatureCapacity]
                        : undefined,
                      key: "signature-capacity",
                    },
                  ]
                : []),
            ],
          },
        ]
      : [];

  return (
    <Modal
      title={`${documentTypeMap[data.documentType]} Details`}
      isOpen={isOpen}
      onClose={onClose}
      size="wide"
      primaryButtonProps={{
        label: "Close",
        trackingId: TaxDocumentationDetailsModalTrackingId.PrimaryBtn,
        onClick: onClose,
      }}
      closeButtonTrackingId={TaxDocumentationDetailsModalTrackingId.CloseBtn}
    >
      <DenseRowGroup
        sections={[
          generalDataFields,
          ...wFormTinFields,
          ...dpsResidenceFields,
          ...controllingPersonFields,
          ...(w8TreatyFields.length > 0
            ? [
                {
                  key: "treaty-fields",
                  rows: w8TreatyFields,
                },
              ]
            : []),
          ...vatFields,
          ...certificationFields,
        ]}
      />
    </Modal>
  );
};

export default TaxDocumentationDetailsModal;
