import { TRACKING_EXCLUDE_CLASS } from "@taxbit-dashboard/commons";
import { Body, Flex } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

type ValueListProps = {
  values?: string[];
};

const ValueList: React.FC<ValueListProps> = ({ values }) => {
  return values && values.length > 0 ? (
    <Flex direction="column" className={TRACKING_EXCLUDE_CLASS}>
      {values.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Body key={`${value}-${index}`}>{value}</Body>
      ))}
    </Flex>
  ) : (
    COSMIC_VALUE_PLACEHOLDER
  );
};

export default ValueList;
