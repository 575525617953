import { hasMultipleValuesGreaterThanZero } from "@taxbit-dashboard/commons";
import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
  Form1099MiscAggregateValues,
  FormItem1099MiscAggregateValues,
} from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import useFormatUsdValue from "../../../../utils/useFormatUsdValue";

const mapFormDataFieldToLabel: Record<string, string> = {
  otherIncome: "Other Income",
  rents: "Rents",
  royalties: "Royalties",
  substitutePayments: "Substitute Payments",
  fishingBoatProceeds: "Fishing Boat Proceeds",
  fishPurchasedForResale: "Fish Purchased for Resale",
  section409ADeferrals: "Section 409A Deferrals",
  cropInsuranceProceeds: "Crop Insurance Proceeds",
  excessGoldenParachutePayments: "Excess Golden Parachute Payments",
  medicalAndHealthCarePayments: "Medical Payments",
  grossProceedsPaidToAnAttorney: "Gross Proceeds",
};

const mapIngestedFieldToLabel: Record<string, string> = {
  taxYearTotalOtherIncome: "Other Income",
  taxYearTotalRentIncome: "Rents",
  taxYearTotalRoyaltiesIncome: "Royalties",
  taxYearTotalMedicalPaymentIncome: "Medical Payments",
  taxYearTotalGrossProceedsPaidToAnAttorneyIncome: "Gross Proceeds",
};

const getFormDataFieldLabel = (
  fields: FormItem1099MiscAggregateValues
): string => {
  const { documentTypeDataSource, ...rest } = fields;

  const hasMultipleFields = hasMultipleValuesGreaterThanZero(
    Object.values(rest)
  );

  if (hasMultipleFields) {
    return "Multiple";
  }

  for (const [fieldKey, field] of Object.entries(rest)) {
    if (fieldKey in mapFormDataFieldToLabel && field > 0) {
      return mapFormDataFieldToLabel[fieldKey] as string;
    }
  }

  return COSMIC_VALUE_PLACEHOLDER;
};

const useGetFormDataFieldAmount = () => {
  const formatUsdValue = useFormatUsdValue();
  return useCallback(
    (fields: FormItem1099MiscAggregateValues): string => {
      const { documentTypeDataSource, ...rest } = fields;

      const hasMultipleFields = hasMultipleValuesGreaterThanZero(
        Object.values(rest)
      );

      if (hasMultipleFields) {
        return "See Export";
      }

      for (const [fieldKey, field] of Object.entries(rest)) {
        if (fieldKey in mapFormDataFieldToLabel && field > 0) {
          return formatUsdValue(field.toString());
        }
      }

      return formatUsdValue("0");
    },
    [formatUsdValue]
  );
};

const getIngestedDataFieldLabel = (
  fields: Form1099MiscAggregateValues
): string => {
  const { documentTypeDataSource, ...rest } = fields;

  const hasMultipleFields = hasMultipleValuesGreaterThanZero(
    Object.values(rest)
  );

  if (hasMultipleFields) {
    return "Multiple";
  }

  for (const [fieldKey, field] of Object.entries(rest)) {
    if (fieldKey in mapIngestedFieldToLabel && field > 0) {
      return mapIngestedFieldToLabel[fieldKey] as string;
    }
  }

  return COSMIC_VALUE_PLACEHOLDER;
};

const useGetIngestedFieldAmount = () => {
  const formatUsdValue = useFormatUsdValue();

  return useCallback(
    (fields: Form1099MiscAggregateValues): string => {
      const { documentTypeDataSource, ...rest } = fields;

      const hasMultipleFields = hasMultipleValuesGreaterThanZero(
        Object.values(rest)
      );

      if (hasMultipleFields) {
        return "See Export";
      }

      for (const [fieldKey, field] of Object.entries(rest)) {
        if (fieldKey in mapIngestedFieldToLabel && field > 0) {
          return formatUsdValue(field.toString());
        }
      }

      return formatUsdValue("0");
    },
    [formatUsdValue]
  );
};

const useForm1099MiscEligibilityTableColumns = () => {
  const getIngestedFieldAmount = useGetIngestedFieldAmount();
  const getFormDataFieldAmount = useGetFormDataFieldAmount();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        id: "formSpecificFieldsField",
        label: "Field",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099MiscIngested
          ) {
            return getIngestedDataFieldLabel(formSpecificFields);
          }

          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099MiscFormData
          ) {
            return getFormDataFieldLabel(formSpecificFields);
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
      {
        key: "formSpecificFields",
        id: "formSpecificFieldsAmount",
        label: "Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099MiscIngested
          ) {
            return getIngestedFieldAmount(formSpecificFields);
          }

          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099MiscFormData
          ) {
            return getFormDataFieldAmount(formSpecificFields);
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
    ],
    [getFormDataFieldAmount, getIngestedFieldAmount]
  );
};

export default useForm1099MiscEligibilityTableColumns;
