import { logError } from "@taxbit-dashboard/commons";

import { initializeDatadog } from "./vendors/datadog";
import { initializeFullStory } from "./vendors/fullStory";
import { initializeLaunchDarkly } from "./vendors/launchDarkly";
import { initializePendo } from "./vendors/pendo/pendo";
import { initializeZendesk } from "./vendors/zendesk";

const initializeAppVendors = async () => {
  try {
    // Needs to happen first to log errors from other vendors
    initializeDatadog();
    initializeFullStory();
    initializePendo();
    initializeZendesk();

    return await initializeLaunchDarkly();
  } catch (error) {
    logError({ message: "Error initializing dashboard app vendors", error });

    // In the event that we hit an error state, return an empty wrapper
    // in place of the LaunchDarklyProvider.
    // eslint-disable-next-line react/display-name
    return ({ children }: { children: JSX.Element }) => <>{children}</>;
  }
};

export default initializeAppVendors;
