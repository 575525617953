import { Notification } from "@taxbit-dashboard/rest";
import { BodySmall, Flex, H4, InlineSpinner } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import styled from "styled-components";

import { notificationCategoryToUiConfigMap } from "../../../api/notifications/notificationsApiTypes";

type Props = {
  notification: Notification;
};

const MAX_DISPLAYED_PROGRESS = 0.95;

const ActiveJobListItem: React.FC<Props> = ({
  notification: {
    category,
    description,
    isComplete,
    progressTickCount,
    completeAtNTicks,
  },
}) => {
  const { formatWholePercentage } = useCosmicLocalizationContext();

  const { label } = notificationCategoryToUiConfigMap[category];

  const completionPercentage = isComplete
    ? MAX_DISPLAYED_PROGRESS
    : Math.min(
        (progressTickCount ?? 0) / (completeAtNTicks ?? 1),
        MAX_DISPLAYED_PROGRESS
      );

  return (
    <Wrapper>
      <FullWidthFlex direction="column" gap="xs">
        <Flex alignItems="center">
          <Flex alignItems="center" gap="s">
            <H4>{label}</H4>
          </Flex>
        </Flex>
        <BodySmall>
          <span>{formatWholePercentage(completionPercentage)} complete</span> |{" "}
          <span>{description}</span>
        </BodySmall>
      </FullWidthFlex>
      <InlineSpinner />
    </Wrapper>
  );
};

export default ActiveJobListItem;

const Wrapper = styled.div(
  ({ theme }) => `
  display: flex;
  width: 100%;
  padding: ${theme.spacing.l};
  background-color: theme.color.white,
  gap: ${theme.spacing.m};
  align-items: center;
`
);

const FullWidthFlex = styled(Flex)`
  width: 100%;
`;
