import { Drawer, Button } from "@taxbit-private/cosmic";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import AccountValidationsEligibilityStatusFilter from "./AccountValidationsEligibilityStatusFilter";
import EligibilityAccountIdsFilter from "./EligibilityAccountIdsFilter";
import EligibilityFormStatusFilter from "./EligibilityFormStatusFilter";
import EligibilityStatusFilter from "./EligibilityStatusFilter";
import useEligibilityFiltersDrawerForm from "./useEligibilityFiltersDrawerForm";

export enum EligibilityFiltersDrawerTrackingId {
  CloseButton = "irEligibilityFiltersDrawerCloseButton",
  ApplyButton = "irEligibilityFiltersDrawerApplyButton",
  CancelButton = "irEligibilityFiltersDrawerCancelButton",
  ClearAllFiltersButton = "irEligibilityFiltersDrawerClearFiltersButton",
}

const FILTER_DRAWER_FORM_ID = "eligibility-filters-drawer-form";

type EligibilityFiltersDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const EligibilityFiltersDrawer = ({
  isOpen,
  onClose,
}: EligibilityFiltersDrawerProps) => {
  const {
    clearAllFormFilters,
    formMethods,
    formType,
    handleSubmit,
    hasFiltersApplied,
    isFormDirty,
    resetForm,
  } = useEligibilityFiltersDrawerForm();

  const closeAndReset = () => {
    onClose();
    resetForm();
  };

  return (
    <Drawer
      onClose={closeAndReset}
      isOpen={isOpen}
      title="Filters"
      closeButtonTrackingId={EligibilityFiltersDrawerTrackingId.CloseButton}
      primaryButtonProps={{
        label: "Apply",
        trackingId: EligibilityFiltersDrawerTrackingId.ApplyButton,
        isDisabled: !isFormDirty,
        type: "submit",
        form: FILTER_DRAWER_FORM_ID,
      }}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: EligibilityFiltersDrawerTrackingId.CancelButton,
        onClick: closeAndReset,
      }}
    >
      <FormProvider {...formMethods}>
        <FlexForm
          id={FILTER_DRAWER_FORM_ID}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(e, () => onClose());
          }}
        >
          <EligibilityStatusFilter formType={formType} />
          <AccountValidationsEligibilityStatusFilter />
          <EligibilityFormStatusFilter />
          <EligibilityAccountIdsFilter />
          {hasFiltersApplied && (
            <NarrowedButton
              onClick={clearAllFormFilters}
              label="Clear All Filters"
              variant="anchor-primary-inline"
              trackingId={
                EligibilityFiltersDrawerTrackingId.ClearAllFiltersButton
              }
            />
          )}
        </FlexForm>
      </FormProvider>
    </Drawer>
  );
};

const NarrowedButton = styled(Button)`
  max-width: fit-content;
`;

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

export default EligibilityFiltersDrawer;
