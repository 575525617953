import { PayerAddress } from "@taxbit-dashboard/rest";

import { countryOptions, AddressCountry } from "../../../utils/countryOptions";

const formatPayerAddress = (address: PayerAddress): string[] => {
  const { firstLine, secondLine, city, stateOrProvince, country, postalCode } =
    address;

  const countryName: string = (
    countryOptions.find((option) => option.code === country) as AddressCountry
  ).name;

  return [
    [firstLine, secondLine].filter(Boolean).join(", "),
    [city, stateOrProvince, postalCode].filter(Boolean).join(", "),
    countryName,
  ].filter(Boolean);
};

export default formatPayerAddress;
