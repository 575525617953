import { AssetAmount, LineItem } from "@taxbit-dashboard/rest";
import { Big } from "big.js";

const sumUpFees = (fees: LineItem[] | undefined): AssetAmount | undefined => {
  const feeAsset = fees?.[0]?.assetAmount.asset;
  const feeAssetId = feeAsset?.id;

  const feeSummed =
    feeAssetId &&
    fees?.reduce((sum, fee) => {
      if (fee.assetAmount.asset.id !== feeAssetId) {
        return sum;
      }
      return sum.plus(fee.assetAmount.amount);
    }, new Big(0));

  return feeSummed && { amount: feeSummed.toString(), asset: feeAsset };
};

export default sumUpFees;
