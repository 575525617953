import { ReactLocation, Router } from "@tanstack/react-location";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { theme } from "@taxbit-private/cosmic";
import { CosmicLocalizationContextProvider } from "@taxbit-private/cosmic-localization";
import { render } from "@testing-library/react";
import { parse, stringify } from "qs";
import * as React from "react";
import { ThemeProvider } from "styled-components";

export const testQueryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

// Mirroring the actual react location pattern
// in AppRouter.tsx
export const testReactLocation = new ReactLocation({
  parseSearch: (searchStr) => parse(searchStr, { ignoreQueryPrefix: true }),
  stringifySearch: (searchParams) => stringify(searchParams),
});

/**
 * Wraps the given elements in the necessary providers for a correct
 * component testing environment and calls RTL `render` on the wrapped element.
 *
 * To use the rendered element in a test, import `screen` from @testing-library/react:
 * ```
 * import { screen } from "@testing-library/react";
 *
 * it("renders", () => {
 *  renderWithProviders(<div>Some text</div>);
 *  expect(screen.getByText("Some text")).toBeInTheDocument();
 * })
 * ```
 */
export const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <QueryClientProvider client={testQueryClient}>
    <ThemeProvider theme={theme}>
      <Router location={testReactLocation} routes={[]}>
        <CosmicLocalizationContextProvider
          initialData={{ locale: "en-US", currency: "USD" }}
        >
          {children}
        </CosmicLocalizationContextProvider>
      </Router>
    </ThemeProvider>
  </QueryClientProvider>
);

const renderWithProviders = (
  children: React.ReactElement,
  {
    providers,
  }: { providers?: React.FC<React.PropsWithChildren>[] } | undefined = {}
) => {
  if (providers) {
    render(
      <Wrapper>
        {providers.reduce(
          (content, Provider) => (
            <Provider>{content}</Provider>
          ),
          children
        )}
      </Wrapper>
    );
  } else {
    render(<Wrapper>{children}</Wrapper>);
  }
};

export default renderWithProviders;
