import { YearEndFairMarketValue } from "@taxbit-dashboard/rest";

export const getMostRecentFairMarketValue = (
  yearEndFairMarketValues?: YearEndFairMarketValue[]
) => {
  if (!yearEndFairMarketValues) {
    return undefined;
  }

  const sortedFairMarketValues = yearEndFairMarketValues.sort(
    (a, b) => b.yearEnd - a.yearEnd
  );

  return sortedFairMarketValues[0]?.fairMarketValue;
};
