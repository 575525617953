import { zodResolver } from "@hookform/resolvers/zod";
import {
  createSingleInstanceHookContext,
  useLoginWithReturnTo,
} from "@taxbit-dashboard/commons";
import { Company, Organization } from "@taxbit-dashboard/rest";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  OrganizationAndCompanyPickerFormFields,
  organizationAndCompanyPickerFormFieldSchema,
} from "./organizationAndCompanyPickerFormFieldSchema";
import useOrganizationAndCompanyPickerData from "./useOrganizationAndCompanyPickerData";

export const ORGANIZATION_AND_COMPANY_PICKER_FORM_ID =
  "organization-and-company-picker-form";

const getFormDataParams = (company?: Company, organization?: Organization) => ({
  company,
  organization,
});

const useTopNavigationActionMenu = () => {
  const {
    organizations,
    isLoading,
    currentCompany,
    currentOrganization,
    currentCompanyUser,
    isSwitchingOrg,
    isOrganizationDropdownVisible,
    isOrgAndCompanyPickerOpen,
    organizationsForSelectedCompany,
    setIsSwitchingOrg,
    setSearchCompany,
    setSearchOrganization,
    setOrganizationsForSelectedCompany,
    setIsOrgAndCompanyPickerOpen,
    setIsOrganizationDropdownVisible,
    filteredCompanies,
    filteredOrganizations,
    searchCompany,
    searchOrganization,
    hasMoreThanOneOrganizationForCurrentCompany,
    hasAccessToMoreThanOneOrganization,
  } = useOrganizationAndCompanyPickerData();

  const loginWithReturnTo = useLoginWithReturnTo();

  const defaultValues = useMemo(
    () => getFormDataParams(currentCompany, currentOrganization),
    [currentCompany, currentOrganization]
  );

  const formMethods = useForm<OrganizationAndCompanyPickerFormFields>({
    resolver: zodResolver(organizationAndCompanyPickerFormFieldSchema),
    defaultValues,
  });

  const { setValue, handleSubmit, watch } = formMethods;

  const currentCompanySelectedInDropdown = watch("company");

  const currentOrganizationSelectedInDropdown = watch("organization");

  const resetForm = useCallback(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  /**
   * Because this context is called at the top level, the form is sometimes instantiated as the initial org/company
   * load in after switching or logging in. This effect just ensures that we begin with a correctly populated form.
   */
  useEffect(() => {
    resetForm();
  }, [resetForm]);

  /**
   * We create a ref so that we can use it to programatically close the action menu on click. In the real Cosmic
   * `ActionMenu` component, this is built-in behavior from HeadlessUI. We cannot use that component here because
   * of the presence of the organization picker form so we have to approximate it.
   */
  const actionMenuTargetButtonRef = useRef<HTMLButtonElement | null>(null);

  /**
   * Takes care of wiping all necessary state throughout the menu.
   */
  const onCloseActionMenu = useCallback(() => {
    setIsOrgAndCompanyPickerOpen(false);
    resetForm();
    setSearchCompany("");
    setSearchOrganization("");
  }, [
    resetForm,
    setIsOrgAndCompanyPickerOpen,
    setSearchCompany,
    setSearchOrganization,
  ]);

  /** Close the action menu by manually triggering a click on the target button. */
  const closeActionMenu = useCallback(() => {
    if (actionMenuTargetButtonRef.current) {
      actionMenuTargetButtonRef.current.click();
    }
  }, []);

  const changeOrganization = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void handleSubmit(async ({ organization }) => {
        setIsSwitchingOrg(true);
        closeActionMenu();
        // There is no way to switch organizations using Auth0 without a redirect/re-login
        await loginWithReturnTo({
          authorizationParams: {
            organization: organization.authOrganizationId,
          },
        });
      })(e);
    },
    [handleSubmit, setIsSwitchingOrg, loginWithReturnTo, closeActionMenu]
  );

  // Based on the company selected in the company dropdown, update the list of organizations for the company selected.
  // Also check if the org dropdown should be visible based on the updated list of organizations.
  useEffect(() => {
    const updatedOrganizationsForCurrentCompany = organizations.filter(
      (organization) =>
        organization.companyId === currentCompanySelectedInDropdown?.companyId
    );

    if (
      JSON.stringify(updatedOrganizationsForCurrentCompany) !==
      JSON.stringify(organizationsForSelectedCompany)
    ) {
      setOrganizationsForSelectedCompany(updatedOrganizationsForCurrentCompany);

      if (updatedOrganizationsForCurrentCompany[0]) {
        setValue("organization", updatedOrganizationsForCurrentCompany[0]);
      }

      setIsOrganizationDropdownVisible(
        updatedOrganizationsForCurrentCompany.length > 1
      );
    }
  }, [
    organizations,
    currentCompanySelectedInDropdown,
    setOrganizationsForSelectedCompany,
    setIsOrganizationDropdownVisible,
    setValue,
    organizationsForSelectedCompany,
  ]);

  return {
    isLoading,
    currentCompanyUser,
    currentOrganization,
    formMethods,
    organizations,
    changeOrganization,
    isSwitchingOrg,
    setIsSwitchingOrg,
    isOrganizationDropdownVisible,
    setSearchCompany,
    setSearchOrganization,
    isOrgAndCompanyPickerOpen,
    setIsOrgAndCompanyPickerOpen,
    filteredCompanies,
    filteredOrganizations,
    searchCompany,
    searchOrganization,
    hasMoreThanOneOrganizationForCurrentCompany,
    hasAccessToMoreThanOneOrganization,
    resetForm,
    setValue,
    handleSubmit,
    currentCompanySelectedInDropdown,
    currentOrganizationSelectedInDropdown,
    actionMenuTargetButtonRef,
    closeActionMenu,
    onCloseActionMenu,
  };
};

export const {
  Provider: TopNavigationActionMenuContextProvider,
  useContextHook: useTopNavigationActionMenuContext,
} = createSingleInstanceHookContext(
  useTopNavigationActionMenu,
  "useTopNavigationActionMenuContext"
);
