import {
  Box,
  BoxAccent,
  ContentErrorEmptyState,
  Divider,
  Flex,
  H4,
  NumericalRowGroup,
} from "@taxbit-private/cosmic";
import { Legend, PieChart } from "@taxbit-private/cosmic-charts";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";
import styled from "styled-components";

import {
  EligibleUsersCountTrackingIds,
  PieSection,
} from "./EligibleUsersCountRows";
import { useEligibilityDataContext } from "../context/useEligibilityData";

const EligibleUsersCount = () => {
  const { eligibilityCounts, isEligibilityCountsError } =
    useEligibilityDataContext();
  const { completeAccountData = 0, incompleteAccountData = 0 } =
    eligibilityCounts?.eligible ?? {};

  const total = completeAccountData + incompleteAccountData;

  const sections: PieSection[] = useMemo(
    () => [
      {
        key: "CompleteAccountData",
        label: "Complete Account Data",
        value: completeAccountData,
        color: "green",
      },
      {
        key: "IncompleteAccountData",
        label: "Incomplete Account Data",
        value: incompleteAccountData,
        color: "red",
      },
    ],
    [completeAccountData, incompleteAccountData]
  );

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <Box headElement={<BoxAccent background="primary" />}>
      <Flex direction="column" padding="contentPadding" gap="m">
        <Flex justifyContent="space-between">
          <H4>Eligible Accounts</H4>
          {!isEligibilityCountsError && (
            <H4 trackingId={EligibleUsersCountTrackingIds.Total}>
              {formatWholeQuantity(total)}
            </H4>
          )}
        </Flex>
        <Divider />
        {isEligibilityCountsError ? (
          <ContentErrorEmptyState entity="eligible accounts" />
        ) : total > 0 ? (
          <FlexWithItemCentered growItems={true} gap="m">
            <PieChart
              sections={sections}
              getSectionKey={({ key }) => key}
              getSectionValue={({ value }) => value}
              getSectionColor={({ color }) => color}
              getSectionTooltipContent={({ label }) => label}
              size="xxs"
            />
            <DivWithBiggerFlexValue>
              <Legend
                sections={sections}
                direction="vertical"
                getSectionKey={({ key }) => key}
                getSectionLabel={({ label }) => label}
                getSectionColor={({ color }) => color}
                getSectionValue={({ value }) => value}
              />
            </DivWithBiggerFlexValue>
          </FlexWithItemCentered>
        ) : (
          <NumericalRowGroup
            rows={[
              {
                label: "Complete Account Data",
                value: completeAccountData,
                key: "completeAccountData",
              },
              {
                label: "Incomplete Account Data",
                value: incompleteAccountData,
                key: "incompleteAccountData",
              },
            ]}
          />
        )}
      </Flex>
    </Box>
  );
};

const DivWithBiggerFlexValue = styled.div`
  flex: 1.5;
`;

const FlexWithItemCentered = styled(Flex)`
  align-items: center;
`;

export default EligibleUsersCount;
