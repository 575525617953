import { Navigate } from "@tanstack/react-location";
import {
  DashboardAppRoutePath,
  useOrganizationPrefixedNavigation,
  UserPermission,
  useUserPermission,
} from "@taxbit-dashboard/commons";

/**
 * This component serves as a reroute component to ensure that upon hitting a
 * base URL for the dashboard, the company user is redirected to the appropriate
 * landing page based on their app access and permissions.
 */
const Launch = () => {
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();
  const hasReadAccountsPermission = useUserPermission(
    UserPermission.ReadAccounts
  );

  /**
   * The only role that will not have the read accounts permission is a tech
   * support role that can be safely directly to company user management instead.
   *
   * At the time being, we have no "safe" pages (i.e., pages that require no permissions
   * to view) to direct users to upon launch. So, we just choose one of these instead.
   */
  if (hasReadAccountsPermission) {
    return <Navigate to={buildOrganizationPrefixedPath("/overview")} />;
  } else {
    return (
      <Navigate
        to={buildOrganizationPrefixedPath(
          DashboardAppRoutePath.CompanyUserManagement
        )}
      />
    );
  }
};

export default Launch;
