import { useDashboardStore } from "@taxbit-dashboard/commons";
import { ReleasedDocumentId } from "@taxbit-dashboard/rest";

import { useGetTaxFormUrl } from "../../../../../api/tax-forms/taxFormsApi";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";

const REFETCH_INTERVAL = 5 * 60 * 1000;

export const getDownloadTaxFormErrorToastTrackingId = (
  documentId: ReleasedDocumentId
) => `downloadTaxForm${documentId}ErrorToast`;

const useDownloadTaxForm = (
  documentId: ReleasedDocumentId,
  formTitle: string
) => {
  const addToast = useDashboardStore((store) => store.addToast);

  const onError = () => {
    addToast({
      message: `${formTitle} failed to download. Please try again.`,
      trackingId: getDownloadTaxFormErrorToastTrackingId(documentId),
      variant: "danger",
      timeoutMs: TOAST_TIMEOUT,
    });
  };

  const { data, isLoading, refetch } = useGetTaxFormUrl(
    documentId,
    REFETCH_INTERVAL
  );

  const onClick = () => {
    if (data?.url) {
      window.open(data.url, "_blank");
    } else {
      void refetch();
      onError();
    }
  };

  return {
    onClick,
    isLoading,
  };
};

export default useDownloadTaxForm;
