import parseDataWithSchema from "./parseDataWithSchema";
import snakeCaseKeys from "./snakeCaseKeys";
import JsonData from "./types/JsonData";
import JsonDataZodSchema from "./types/JsonDataZodSchema";

/**
 * Prepares data from the consumer to be sent through a network request.
 * @param requestData
 * @param requestDataSchema
 */
const prepareRequestData = <TRequestDataSchema extends JsonDataZodSchema>({
  requestData,
  requestDataSchema,
}: {
  requestData?: JsonData;
  requestDataSchema?: TRequestDataSchema;
}) => {
  if (requestData === undefined) {
    return undefined;
  }

  // Purposely never allow request body data unless a schema has
  // been provided to validate it.
  if (!requestDataSchema) {
    throw new Error("Request data is not allowed for this type of request.");
  }

  const parsedRequestData = parseDataWithSchema(
    requestData,
    requestDataSchema,
    "Request data did not match expected schema."
  );

  const snakeCasedData =
    typeof parsedRequestData === "object"
      ? snakeCaseKeys(parsedRequestData)
      : parsedRequestData;

  return JSON.stringify(snakeCasedData);
};

export default prepareRequestData;
