import {
  navigateToUrl,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import {
  Notification,
  NotificationActionType,
  NotificationAction,
} from "@taxbit-dashboard/rest";
import { Flex, Button, ButtonProps } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import { useFetchNotificationDownloadUrl } from "../../../api/notifications/notificationsApi";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

type Props = Pick<Notification, "actions" | "notificationId"> & {
  context: NotificationsContext;
  markNotificationAsRead: (notificationId: string) => void;
};

enum NotificationsListItemCtasTrackingId {
  Primary = "primary-cta",
  Secondary = "secondary-cta",
}

const NotificationsListItemCtas: React.FC<Props> = ({
  actions,
  context,
  notificationId,
  markNotificationAsRead,
}) => {
  const { fetchNotificationDownloadUrl, isFetchingDownloadUrl } =
    useFetchNotificationDownloadUrl(notificationId);
  const { prefixedNavigate } = useOrganizationPrefixedNavigation();
  const { handleClose } = useNotificationsContext(context);

  const getCtaButtonProps = useCallback(
    (action: NotificationAction, isSecondaryAction: boolean) => {
      const sharedProps = {
        variant: isSecondaryAction
          ? "button-tertiary"
          : (actions?.length ?? 0) > 1
            ? "button-primary"
            : "anchor-primary",
        size: "small",
        label: action.actionText,
        trackingId: `${isSecondaryAction ? NotificationsListItemCtasTrackingId.Secondary : NotificationsListItemCtasTrackingId.Primary}-${notificationId}`,
      } as ButtonProps;

      switch (action.type) {
        case NotificationActionType.Download: {
          return {
            ...sharedProps,
            onClick: () => {
              void fetchNotificationDownloadUrl();
              markNotificationAsRead(notificationId);
              handleClose?.();
            },
            loadingText: "Downloading...",
            isLoading: isFetchingDownloadUrl,
          };
        }
        case NotificationActionType.Href: {
          return {
            ...sharedProps,
            onClick: () => {
              prefixedNavigate({ to: action.actionUrl });
              markNotificationAsRead(notificationId);
              handleClose?.();
            },
          };
        }
        case NotificationActionType.Support: {
          return {
            ...sharedProps,
            onClick: () => {
              navigateToUrl(action.actionUrl);
              markNotificationAsRead(notificationId);
              handleClose?.();
            },
          };
        }
        default: {
          return sharedProps;
        }
      }
    },
    [
      actions?.length,
      fetchNotificationDownloadUrl,
      handleClose,
      isFetchingDownloadUrl,
      markNotificationAsRead,
      notificationId,
      prefixedNavigate,
    ]
  );

  if (!actions || actions.length === 0) {
    return undefined;
  }

  const [primaryAction, secondaryAction] = actions;

  return (
    <Flex gap="s" padding="xs none none none">
      {primaryAction && <Button {...getCtaButtonProps(primaryAction, false)} />}
      {secondaryAction && (
        <Button {...getCtaButtonProps(secondaryAction, true)} />
      )}
    </Flex>
  );
};

export default NotificationsListItemCtas;
