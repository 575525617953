import { useNavigate, useSearch } from "@tanstack/react-location";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useMemo } from "react";

import useSharedFormDropdownData from "./useSharedFormDropdownData";
import validateIrParams, { IrSearchLocationParams } from "./validateIrParams";
import getLastTaxYear from "../../../utils/getLastTaxYear";

/**
 * @deprecated This hook provides parsed data from the shared `irFormTypesByYear` feature flag. All
 * new form flagging should use individual feature flags and `useFormFeatureFlagDropdowns` instead.
 */
const useIrDropdowns = (
  shouldUseFormItemsForms = false,
  onFormChangeCallback?: (form: DashboardFormType) => void,
  onYearChangeCallback?: (year: FourDigitYear) => void
) => {
  const { forms, formTypeToYearsMap, irFormTypesByYear } =
    useSharedFormDropdownData({
      shouldUseFormItemsForms,
    });

  const navigate = useNavigate<IrSearchLocationParams>();

  const rawUrlParams = useSearch<IrSearchLocationParams>();
  const validatedUrlParams = useMemo(() => {
    return validateIrParams(
      rawUrlParams,
      irFormTypesByYear,
      shouldUseFormItemsForms
    );
  }, [rawUrlParams, irFormTypesByYear, shouldUseFormItemsForms]);

  // We show a top-level error state if there are no tax forms / years configured, so the fallbacks
  // for `currentForm` and `currentYear` are just to appease Typescript.

  const currentForm =
    validatedUrlParams?.formType ?? forms[0] ?? DashboardFormType.Irs1099B;

  const currentYear = (() => {
    if (validatedUrlParams?.formDate) {
      return validatedUrlParams.formDate;
    } else if (forms[0] && formTypeToYearsMap[forms[0]]) {
      return formTypeToYearsMap[forms[0]]?.[0] ?? getLastTaxYear().year;
    } else {
      return getLastTaxYear().year;
    }
  })();

  const onFormChange = (form: DashboardFormType) => {
    navigate({
      search: {
        formType: form,
        formDate: `${
          formTypeToYearsMap[form]?.find(
            (formYear) => formYear === validatedUrlParams?.formDate
          ) ?? formTypeToYearsMap[form]?.[0]
        }`,
      },
    });
    onFormChangeCallback?.(form);
  };

  const onYearChange = (year: FourDigitYear) => {
    navigate({
      search: {
        formType: currentForm,
        formDate: `${year}`,
      },
    });
    onYearChangeCallback?.(year);
  };

  return {
    forms,
    currentForm,
    onFormChange,
    years: formTypeToYearsMap[currentForm] ?? [],
    currentYear,
    onYearChange,
  };
};

export default useIrDropdowns;
