import { AccountId, Transaction } from "@taxbit-dashboard/rest";
import { useEffect, useMemo, useState } from "react";

import useGetTransactionsCsvRow from "./useGetTransactionsCsvRow";
import { useGetTransactionsForCsv } from "../../../../../api/transactionsApi";
import { CsvHeader, generateCsv } from "../../../../../utils/csvGenerator";

// Each transaction request returns 100 transactions and takes ~1.3 sec
// To limit the response time for the user to 30 seconds we make only 30 API calls and get 3000 transactions
const TRANSACTION_LENGTH_LIMIT = 3000;

const COLUMNS: CsvHeader[] = [
  { key: "dateTime", header: "DateTime" },
  { key: "transactionType", header: "Transaction Type" },
  { key: "assetInQuantity", header: "Asset In (Quantity)" },
  { key: "assetInCurrency", header: "Asset In (Currency)" },
  { key: "assetOutQuantity", header: "Asset Out (Quantity)" },
  { key: "assetOutCurrency", header: "Asset Out (Currency)" },
  { key: "transactionFeeQuantity", header: "Transaction Fee (Quantity)" },
  { key: "transactionFeeCurrency", header: "Transaction Fee (Currency)" },
  { key: "transactionValueFiat", header: "Market Value" },
  { key: "feeValueFiat", header: "Fee Value" },
];

const useGetTransactionsCsv = ({
  accountId,
  onError,
}: {
  accountId?: AccountId;
  onError: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>();
  const [cursor, setCursor] = useState<string>();

  const enabled =
    isLoading &&
    (!transactions ||
      (transactions.length < TRANSACTION_LENGTH_LIMIT && !!cursor));

  const { data, isError } = useGetTransactionsForCsv({
    accountUuid: accountId,
    enabled,
    cursor,
  });

  const getTransactionsCsvRow = useGetTransactionsCsvRow();

  useEffect(() => {
    if (isError && isLoading) {
      setTransactions(undefined);
      setIsLoading(false);
      onError();
    }
  }, [isError, isLoading, onError]);

  useEffect(() => {
    if (data?.data && enabled) {
      setCursor(data.meta?.page?.next);
      setTransactions((prevTransactions) => [
        ...(prevTransactions ?? []),
        ...data.data,
      ]);
    }
  }, [data, enabled]);

  useEffect(() => {
    if (!enabled) {
      setIsLoading(false);
    }
  }, [enabled]);

  const csv = useMemo(() => {
    if (!isLoading && transactions) {
      return generateCsv(
        COLUMNS,
        transactions.map((transaction) => getTransactionsCsvRow(transaction))
      );
    }
    return undefined;
  }, [getTransactionsCsvRow, isLoading, transactions]);

  const generateTransactionsCsv = () => {
    setIsLoading(true);
    setCursor(undefined);
    setTransactions(undefined);
  };

  return {
    isLoading,
    csv,
    generateTransactionsCsv,
  };
};

export default useGetTransactionsCsv;
