import { irFormTypeToDisplayNameMap } from "@taxbit-dashboard/commons";
import { FormCountByType } from "@taxbit-dashboard/rest";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
  Flex,
} from "@taxbit-private/cosmic";
import {
  BarChart,
  CosmicChartsTooltipContent,
  hasChartData,
} from "@taxbit-private/cosmic-charts";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import { useOverviewDataContext } from "../context/useOverviewData";
import FixedWidthNumericalRowGroup from "../shared/FixedWidthNumericalRowGroup";
import FormsYearPicker from "../shared/FormsYearPicker";

const FormDataByFormTypeChart = () => {
  const {
    formItemsQuery: { isError, isFetching, data, meta },
    hasFormItemYears,
  } = useOverviewDataContext();

  const total = useMemo(() => meta?.total ?? 0, [meta]);

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const numericalRows = useMemo(() => {
    if (data) {
      const headerRow = {
        key: "header",
        label: "Form Type",
        value: "Total",
        variant: "bold",
        hasDivider: true,
      } as const;

      const formRows = data.map(({ documentType, totalFormItemsCreated }) => ({
        key: documentType,
        label: irFormTypeToDisplayNameMap[documentType],
        value: totalFormItemsCreated,
      }));

      const totalRow = {
        key: "total",
        label: "Total",
        value: total,
        variant: "bold",
      } as const;

      return [headerRow, ...formRows, totalRow];
    } else {
      return [];
    }
  }, [total, data]);

  const getBarTooltipContent = useCallback(
    ({ totalFormItemsCreated, documentType }: FormCountByType) => (
      <CosmicChartsTooltipContent
        items={[
          {
            label: irFormTypeToDisplayNameMap[documentType],
            value: formatWholeQuantity(totalFormItemsCreated),
          },
        ]}
      />
    ),
    [formatWholeQuantity]
  );

  if (!hasFormItemYears) {
    return undefined;
  }

  return (
    <Card
      title="Form Data Records by Form Type"
      utilityElement={<FormsYearPicker paramKey="forms" />}
    >
      {isFetching ? (
        <ContentSpinner />
      ) : isError ? (
        <ContentErrorEmptyState />
      ) : hasChartData(data) ? (
        <Flex padding="contentPadding" alignItems="center">
          <FixedWidthNumericalRowGroup rows={numericalRows} />
          <Flex padding="none l" alignSelf="stretch">
            <Divider direction="vertical" />
          </Flex>
          <BarChart
            bars={data}
            getBarValue={({ totalFormItemsCreated }) => totalFormItemsCreated}
            getBarLabel={({ documentType }) =>
              irFormTypeToDisplayNameMap[documentType]
            }
            getBarTooltipContent={getBarTooltipContent}
          />
        </Flex>
      ) : (
        <ContentEntityEmptyState entity="form data" />
      )}
    </Card>
  );
};

export default FormDataByFormTypeChart;
