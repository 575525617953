import { companySchema, organizationSchema } from "@taxbit-dashboard/rest";
import { z } from "zod";

export const organizationAndCompanyPickerFormFieldSchema = z.object({
  company: companySchema.optional(),
  organization: organizationSchema,
});

export type OrganizationAndCompanyPickerFormFields = z.infer<
  typeof organizationAndCompanyPickerFormFieldSchema
>;
