import { useEffect } from "react";

import usePageScroll from "./usePageScroll";
import { PageContextPageKey } from "../store/page-context/pageContextSliceModel";
import useDashboardStore from "../store/useDashboardStore";

/**
 * Checks for a scroll position stored in global state for the given page
 * context key, and scrolls to the given position if one exists. This hook
 * can be used to scroll back to the last scroll position on a given page
 * when we return back to it.
 */
const useSavedScrollPosition = ({ key }: { key: PageContextPageKey }) => {
  const position = useDashboardStore((state) => state.scrollPositions[key]);
  const setPosition = useDashboardStore((state) => state.setScrollPosition);

  const { pageScrollElementRef, scrollPageToOffset } = usePageScroll();

  useEffect(() => {
    const pageScrollElement = pageScrollElementRef.current;

    if (!pageScrollElement) return () => undefined;

    const handleOnScroll = () => {
      const { scrollTop } = pageScrollElement;
      setPosition(key, scrollTop);
    };

    pageScrollElement.addEventListener("scroll", handleOnScroll);

    scrollPageToOffset(position ?? 0);

    return () => {
      pageScrollElement.removeEventListener("scroll", handleOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSavedScrollPosition;
