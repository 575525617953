import {
  AccountOwnerIssue,
  ControllingPerson,
  ControllingPersonRole,
  FinancialInstitutionType,
  ForeignAccountHolderAccountType,
  KycTaxDocumentType,
  KycTaxDocumentationAddress,
  KycTaxDocumentationDac7Status,
  LimitationOnBenefits,
  SignatureCapacity,
  TaxDocumentStatusValidationResult,
  TaxResidenceTinNotRequiredReason,
  TypeOfIncome,
} from "@taxbit-dashboard/rest";
import { BadgeProps } from "@taxbit-private/cosmic";
import { U } from "ts-toolbelt";

export type TaxDocDetails = U.Strict<{
  documentType: KycTaxDocumentType;
  documentStatus: TaxDocumentStatusValidationResult;
  treatyClaimStatus?: "VALID" | "INVALID";
  name: string;
  taxClassification: string;
  submissionDate: string;
  expirationDate?: string;
  subjectToBackupWithholding?: boolean;
  issues?: AccountOwnerIssue[];
  warnings?: AccountOwnerIssue[];
  permanentAddress?: KycTaxDocumentationAddress;
  treatyCountry?: string;
}>;

export type Dac7Details = {
  interviewStatus: KycTaxDocumentationDac7Status;
  name?: string;
  isIndividual?: boolean;
  isEuResident?: boolean;
  submissionDate?: string;
  expirationDate?: string;
};

export type SelfCertQuestionnaire = {
  documentationStatus: TaxDocumentStatusValidationResult;
  name?: string;
  submissionDate?: string;
  isIndividual?: boolean;
  controllingPersons?: ControllingPerson[];
};

export const taxClassificationMap: Record<string, string> = {
  INDIVIDUAL: "Individual",
  PARTNERSHIP: "Partnership",
  SMLLC: "Single Member LLC",
  LLC_C: "LLC - C Corporation",
  LLC_P: "LLC - Partnership",
  LLC_S: "LLC - S Corporation",
  OTHER: "Other",
  SOLE_PROPRIETOR: "Sole Proprietor",
  C_CORPORATION: "C Corporation",
  S_CORPORATION: "S Corporation",
  TRUST_ESTATE: "Trust Estate",
  CORPORATION: "Corporation",
  SIMPLE_TRUST: "Simple Trust",
  COMPLEX_TRUST: "Complex Trust",
  GRANTOR_TRUST: "Grantor Trust",
  ESTATE: "Estate",
  CENTRAL_BANK_OF_ISSUE: "Central Bank of Issue",
  FOREIGN_GOVERNMENT_CONTROLLED_ENTITY: "Foreign Government Controlled Entity",
  FOREIGN_GOVERNMENT_INTEGRAL_PART: "Foreign Government Integral Part",
  TAX_EXEMPT_ORGANIZATION: "Tax Exempt Organization",
  PRIVATE_FOUNDATION: "Private Foundation",
  INTERNATIONAL_ORGANIZATION: "International Organization",
  ENTITY: "Entity",
};

export const kycTaxDocumentationDac7StatusToBadgePropsMap: Record<
  KycTaxDocumentationDac7Status,
  BadgeProps
> = {
  [KycTaxDocumentationDac7Status.Complete]: {
    label: "Complete",
    variant: "success",
  },
  [KycTaxDocumentationDac7Status.Incomplete]: {
    label: "Incomplete",
    variant: "danger",
  },
};

export const treatyClaimTypeOfIncomeMap: Record<TypeOfIncome, string> = {
  BUSINESS_PROFITS: "Business Profits",
  ROYALTIES_OTHER: "Royalties (other)",
  SERVICES: "Independent Personal Services",
};

export const treatyClaimLimitationOnBenefitsMap: Record<
  LimitationOnBenefits,
  string
> = {
  COMPANY_MEETS_BUSINESS_TEST: "Company meets business test",
  COMPANY_MEETS_DERIVATIVE_TEST: "Company meets derivative test",
  COMPANY_MEETS_EROSION_TEST: "Company meets erosion test",
  FAVORABLE_DETERMINATION: "Favorable determination",
  NO_LOB_ARTICLE: "No LOB article",
  GOVERNMENT: "Government",
  OTHER_ARTICLE_PARAGRAPH: "Other article/paragraph",
  OTHER_TAX_EXEMPT_ORGANIZATION: "Other tax-exempt organization",
  PUBLICLY_TRADED_CORPORATION: "Publicly traded corporation",
  SUBSIDIARY: "Subsidiary",
  TAX_EXEMPT_PENSION: "Tax-exempt pension",
};

export const foreignAccountTypeMap: Record<
  ForeignAccountHolderAccountType,
  string
> = {
  INDIVIDUAL: "Individual",
  CORPORATION: "Corporation",
  PARTNERSHIP: "Partnership",
  TRUST: "Trust",
  OTHER: "Other",
  DISREGARDED_ENTITY: "Disregarded Entity",
  FINANCIAL_INSTITUTION: "Financial Institution",
  PASSIVE_NON_FINANCIAL_ENTITY: "Passive Non-Financial Entity",
  ACTIVE_NON_FINANCIAL_ENTITY: "Active Non-Financial Entity",
};

export const financialInstitutionTypeMap: Record<
  FinancialInstitutionType,
  string
> = {
  DEPOSITORY: "Depository",
  CUSTODIAL: "Custodial",
  INVESTMENT: "Investment",
  INSURANCE_COMPANY: "Insurance Company",
};

export const documentTypeMap: Record<KycTaxDocumentType, string> = {
  [KycTaxDocumentType.W9]: "W-9",
  [KycTaxDocumentType.W8Ben]: "W-8BEN",
  [KycTaxDocumentType.W8BenE]: "W-8BEN-E",
  [KycTaxDocumentType.Dps]: "DPS",
  [KycTaxDocumentType.SelfCertification]: "Self-Certification",
};

export const controllingPersonRoleMap: Record<ControllingPersonRole, string> = {
  SETTLOR: "Settlor",
  TRUSTEE: "Trustee",
  PROTECTOR: "Protector",
  BENEFICIARY: "Beneficiary",
  OTHER: "Other",
  SETTLOR_EQUIVALENT: "Settlor Equivalent",
  TRUSTEE_EQUIVALENT: "Trustee Equivalent",
  PROTECTOR_EQUIVALENT: "Protector Equivalent",
  BENEFICIARY_EQUIVALENT: "Beneficiary Equivalent",
  OTHER_EQUIVALENT: "Other Equivalent",
  OWNER: "Owner",
  OTHER_MEANS: "Other Means",
  SENIOR_MANAGING_OFFICIAL: "Senior Managing Official",
};

export const signatureCapacityMap: Record<SignatureCapacity, string> = {
  OTHER: "Other",
  OFFICER: "Officer",
  EXECUTOR: "Executor",
};

export const tinNotRequiredReasonMap: Record<
  TaxResidenceTinNotRequiredReason,
  string
> = {
  NOT_ISSUED: "Not issued",
  NOT_REQUIRED: "Not required",
  OTHER: "Other",
};
