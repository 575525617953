import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum TaxReportType {
  AccountIdReport = "account-id-report",
  FormsStatusReport = "forms-status-report",
  NegativeAssetBalanceReport = "negative-asset-balance-report",
  ReconciliationReport = "reconciliation-report",
  TransactionCountsReport = "transaction-counts-report",
  TransactionIdReport = "transaction-id-report",
  TinReport = "tin-report",
  TinActionNeeded = "tin-action-needed-report",
  TaxDocReport = "tax-doc-report",
  TaxDocActionNeeded = "tax-doc-action-needed-report",
  VatReport = "vat-report",
  VatActionNeeded = "vat-action-needed-report",
  FormDataReport = "form-data-report",
}

export const taxReportTypeSchema = z.nativeEnum(TaxReportType);

export enum TaxReportStatus {
  InProgress = "in-progress",
  Completed = "completed",
  Failed = "failed",
}

export const taxReportStatusSchema = z.nativeEnum(TaxReportStatus);

export const taxReportSchema = z.object({
  date: z.string().optional(),
  type: z.nativeEnum(TaxReportType),
});

export type ApiTaxReport = z.infer<typeof taxReportSchema>;

export const getTaxReportsResponseSchema = getPublicApiSuccessSchema(
  taxReportSchema.array()
);

export const getTaxReportUrlResponseSchema = getPublicApiSuccessSchema(
  z.object({
    url: z.string(),
  })
);

export type GetTaxReportsParams = {
  "filters[report_type]"?: TaxReportType[];
};

export const generateTaxReportSchema = z.object({
  reportName: z.string(),
  generatedBy: z.string(),
});

export type GenerateTaxReport = z.infer<typeof generateTaxReportSchema>;

export const generateTaxReportResponseSchema = getPublicApiSuccessSchema(
  z.object({
    reportName: z.string(),
  })
);

export type GenerateTaxReportResponse = z.infer<
  typeof generateTaxReportResponseSchema
>;

export const getTaxReportStatusSchema = z.object({
  reportName: taxReportTypeSchema,
  status: taxReportStatusSchema,
  lastFailed: z.string().datetime().optional(),
  lastGenerated: z.string().datetime().optional(),
});

export type GetTaxReportStatus = z.infer<typeof getTaxReportStatusSchema>;

export const getTaxReportStatusResponseSchema = getPublicApiSuccessSchema(
  z.array(getTaxReportStatusSchema)
);

export type GetTaxReportStatusResponse = z.infer<
  typeof getTaxReportStatusResponseSchema
>;
