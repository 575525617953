import { datadogRum } from "@datadog/browser-rum";
import { isDevEnvironment } from "@taxbit-dashboard/rest";

/**
 * We are passing our own message property, so we must explicitly disallow
 * consumers of the `logEvent` helper to also attempt to pass one.
 */
type AdditionalProperties = {
  message?: never;
  [key: PropertyKey]: unknown;
};

/**
 * Logs an error both to the console and to our error tracking vendors. Should
 * be used instead of manually calling `console.error` to ensure that we have
 * all caught errors recorded for triaging and tracing in our vendors.
 *
 * If no error instance is given to be logged, a new one will be created with
 * the provided message for any vendor that requires them.
 */
const logError = ({
  message,
  error,
  additionalProperties = {},
}: {
  message: string;
  error?: unknown;
  additionalProperties?: AdditionalProperties;
}) => {
  try {
    if (error) {
      datadogRum.addError(error, { ...additionalProperties, message });
    } else {
      datadogRum.addError(new Error(message), { ...additionalProperties });
    }
  } catch (e) {
    // In the event that we fail to send our error to Datadog, we will log
    // the original error and the error from DD to the console and hopefully
    // pick up in the top-level error monitoring that DD provides.
    // eslint-disable-next-line no-console
    console.error(
      `Failed to log error in Datadog: ${JSON.stringify(e)}`,
      error,
      additionalProperties
    );
  }

  // If we're running locally, log the error to the console to assist with debugging.
  if (isDevEnvironment()) {
    // eslint-disable-next-line no-console
    console.error(message, error, additionalProperties);
  }
};

export default logError;
