import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardMutationKey,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useDashboardStore,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  TaxReportStatus,
  TaxReportType,
  GenerateTaxReport,
  UnexpectedResponseError,
  GenerateTaxReportResponse,
} from "@taxbit-dashboard/rest";

import { TOAST_TIMEOUT } from "../utils/toastTimeout";

// 10 seconds
const REFETCH_INTERVAL = 10 * 1000;

export const useGetTaxReports = (reportTypes?: TaxReportType[]) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.TaxReports, reportTypes],
    () => restSdk.taxReports.all.get({ "filters[report_type]": reportTypes }),
    createQueryMetaObject(restSdk.taxReports.all.buildPath())
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useGetTaxReportUrl = ({
  reportType,
  onError,
}: {
  reportType?: TaxReportType;
  onError: () => void;
}) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.TaxReportUrl, reportType],
    () => reportType && restSdk.taxReports.all.url.get({ reportType }),
    {
      ...createQueryMetaObject(restSdk.taxReports.all.url.buildPath()),
      enabled: false,
      onError,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const getGenerateReportErrorToastTrackingId = (key: string) =>
  `generate-reports-error-toast-${key}`;

export const getGenerateReportSuccessToastTrackingId = (key: string) =>
  `generate-reports-success-toast-${key}`;

/**
 * Although we normally keep success and error handlers at the component level, the action buttons
 * that use this hook are remounted during the process of report generation when the status updates.
 * The success and error handlers are lost when the component is remounted, so we must define them here
 * to ensure that toasts are shown.
 */
export const useGenerateTaxReport = ({
  requestData,
  userFriendlyReportName,
}: {
  requestData: GenerateTaxReport;
  userFriendlyReportName?: string;
}) => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  const addToast = useDashboardStore((store) => store.addToast);

  return useMutation<GenerateTaxReportResponse, UnexpectedResponseError>(
    () => restSdk.taxReports.generate.post({ requestData }),
    {
      mutationKey: [
        DashboardMutationKey.GenerateTaxReport,
        requestData.reportName,
      ],
      onSuccess: () => {
        addToast({
          message: `Your ${userFriendlyReportName} report is currently generating. Once complete, you can download it from this page or the notifications tab.`,
          trackingId: getGenerateReportSuccessToastTrackingId(
            requestData.reportName
          ),
          variant: "primary",
          timeoutMs: TOAST_TIMEOUT,
        });
        void queryClient.invalidateQueries([DashboardQueryKey.TaxReportStatus]);
      },
      onError: (error) => {
        const errorMessage =
          error.detailedErrors?.[0]?.detail ??
          `Your ${userFriendlyReportName} report failed to generate. Please try again, or contact Taxbit support if the error persists.`;

        addToast({
          message: errorMessage,
          trackingId: getGenerateReportErrorToastTrackingId(
            requestData.reportName
          ),
          variant: "danger",
          timeoutMs: TOAST_TIMEOUT,
        });
      },
    }
  );
};

export const useGetTaxReportStatuses = () => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.TaxReportStatus],
    () => restSdk.taxReports.all.status.get(),
    {
      ...createQueryMetaObject(restSdk.taxReports.all.status.buildPath()),
      refetchInterval: (data) => {
        const inProgressReports = data?.data.filter(
          ({ status }) => status === TaxReportStatus.InProgress
        );

        if (inProgressReports && inProgressReports.length > 0) {
          return REFETCH_INTERVAL;
        }
        return false;
      },
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
