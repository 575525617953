import { irFormTypeToDisplayNameMap } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { Dropdown, Flex } from "@taxbit-private/cosmic";

import {
  CESOP_STATE_MEMEBERS,
  YEARS_WITH_QUARTER_VALUES,
  currentStateMember,
  currentYearWithQuarter,
} from "./cesopDemoValues";
import useIrDropdowns from "./useIrDropdowns";

export enum IrFormYearUtilityDropdownsTrackingId {
  FormDropdown = "irFormDropdown",
  YearDropdown = "irYearDropdown",
  StateMemberDropdown = "irStateMemberDropdown",
  YearQuarterDropdown = "irYearQuarterDropdown",
}

type Props = ReturnType<typeof useIrDropdowns> & {
  isDisabled: boolean;
  size?: "small" | "default";
};

const IrFormYearUtilityDropdowns: React.FC<Props> = ({
  forms,
  years,
  currentForm,
  onFormChange,
  currentYear,
  onYearChange,
  isDisabled,
  size = "default",
}) => {
  const additionalDropdowns = (() => {
    if (currentForm === DashboardFormType.Cesop) {
      return (
        <>
          <Dropdown
            options={CESOP_STATE_MEMEBERS}
            value={currentStateMember}
            getOptionKey={(value) => value.toString()}
            getOptionLabel={(value) => value.toString()}
            onChange={() => undefined}
            trackingId={
              IrFormYearUtilityDropdownsTrackingId.StateMemberDropdown
            }
            size={size}
            isDisabled={isDisabled}
          />
          <Dropdown
            options={YEARS_WITH_QUARTER_VALUES}
            value={currentYearWithQuarter}
            getOptionKey={(value) => value.toString()}
            getOptionLabel={(value) => value.toString()}
            onChange={() => undefined}
            trackingId={
              IrFormYearUtilityDropdownsTrackingId.YearQuarterDropdown
            }
            size={size}
            isDisabled={isDisabled}
          />
        </>
      );
    }

    return (
      <Dropdown
        options={years}
        value={currentYear}
        getOptionKey={(value) => value.toString()}
        getOptionLabel={(value) => value.toString()}
        onChange={onYearChange}
        trackingId={IrFormYearUtilityDropdownsTrackingId.YearDropdown}
        size={size}
        isDisabled={isDisabled}
      />
    );
  })();

  return (
    <Flex gap="s">
      <Dropdown
        options={forms}
        value={currentForm}
        getOptionKey={(value) => value}
        getOptionLabel={(value) => irFormTypeToDisplayNameMap[value]}
        onChange={onFormChange}
        trackingId={IrFormYearUtilityDropdownsTrackingId.FormDropdown}
        size={size}
        isDisabled={isDisabled}
      />
      {additionalDropdowns}
    </Flex>
  );
};

export default IrFormYearUtilityDropdowns;
