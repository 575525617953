declare global {
  // Must use interface here to extend existing type defs
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Navigator {
    msSaveBlob:
      | ((blobOrBase64: Blob | string, filename: string) => void)
      | undefined;
  }
}
// Size limits
// https://stackoverflow.com/questions/695151/data-protocol-url-size-limitations
const downloadFile = (filename: string, data: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  // For IE 11
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.append(elem);
    elem.click();
    elem.remove();
    window.URL.revokeObjectURL(elem.href);
  }
};

export default downloadFile;
