import {
  renderExcludedSpan,
  renderExcludedSpanDefaultToCosmicPlaceholder,
} from "@taxbit-dashboard/commons";
import { KycTaxdocumentationVatStatus } from "@taxbit-dashboard/rest";
import { ActionSnippet } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { byIso } from "country-code-lookup";
import { useMemo } from "react";

import { VatInformation } from "./useGetVatInformation";
import maskData from "../../../../../utils/maskData";
import VatBadge from "../../VatBadge";

const enum VatInformationRowsTrackingId {
  Vatin = "vatin",
}

const useVatInformationRows = ({
  vatInformation,
  shouldUnmaskValue,
}: {
  vatInformation?: VatInformation;
  shouldUnmaskValue: boolean;
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!vatInformation) {
      return undefined;
    }

    const countryName =
      vatInformation.vatinCountry &&
      byIso(vatInformation.vatinCountry)?.country;

    return [
      {
        key: "vat",
        rows: [
          {
            label: "VAT Number",
            content:
              vatInformation.vatStatus ===
              KycTaxdocumentationVatStatus.NotRequired ? (
                renderExcludedSpan("Not Issued")
              ) : (
                <ActionSnippet
                  contents={
                    vatInformation.unmaskedVatin ?? vatInformation.vatin
                  }
                  shouldMask={shouldUnmaskValue}
                  maskContents={(value) => maskData(value)}
                  trackingId={VatInformationRowsTrackingId.Vatin}
                />
              ),
            key: "vat-number",
          },
          {
            label: "Country",
            content: renderExcludedSpanDefaultToCosmicPlaceholder(countryName),
            key: "country",
          },
          ...(vatInformation.vatStatus === KycTaxdocumentationVatStatus.NonEu
            ? []
            : [
                {
                  label: "VAT Number Status",
                  content: (
                    <VatBadge vatValidationStatus={vatInformation.vatStatus} />
                  ),
                  key: "vat-number-status",
                },
                {
                  label: "Last Verified with VIES",
                  content: formatDateTime({
                    date: vatInformation.vatValidationDate,
                  }),
                  key: "last-verified",
                },
              ]),
        ],
      },
    ];
  }, [formatDateTime, vatInformation, shouldUnmaskValue]);
};

export default useVatInformationRows;
