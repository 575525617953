import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { getFormsSearchKeySchema } from "@taxbit-dashboard/rest";
import { useMemo, useState, useEffect } from "react";

import { formsSearchKeyLabelMap } from "../../../../api/information-reporting/forms/formsApiTypes";

const SEARCH_KEY_OPTIONS = getFormsSearchKeySchema.options.map((key) => ({
  key,
  label: formsSearchKeyLabelMap[key],
}));

export type SearchKeyOption = (typeof SEARCH_KEY_OPTIONS)[number];

export const useSearchKeyOptions = () => {
  const { showAlternateExternalId } = useDashboardFeatureFlags();

  const [options, defaultOption] = useMemo(() => {
    if (showAlternateExternalId) {
      return [SEARCH_KEY_OPTIONS, SEARCH_KEY_OPTIONS[0] as SearchKeyOption];
    }
    const filtered = SEARCH_KEY_OPTIONS.filter(
      ({ key }) => key !== "account_alternate_external_id"
    );
    return [filtered, filtered[0] as SearchKeyOption];
  }, [showAlternateExternalId]);

  const [searchKeyOption, setSearchKeyOption] =
    useState<SearchKeyOption>(defaultOption);

  useEffect(() => {
    const isCurrentOptionValid = options.some(
      (option) => option.key === searchKeyOption.key
    );

    if (!isCurrentOptionValid) {
      setSearchKeyOption(defaultOption);
    }
  }, [options, searchKeyOption, defaultOption]);

  return { options, searchKeyOption, setSearchKeyOption };
};
