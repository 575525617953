import { useNavigate } from "@tanstack/react-location";
import { useCallback } from "react";

import useDashboardStore from "../../store/useDashboardStore";

export const buildOrganizationPathPrefix = (organizationId: string) =>
  `/o/${organizationId}`;

type NavigationOptions = Parameters<ReturnType<typeof useNavigate>>[0];

const useOrganizationPrefixedNavigation = () => {
  const navigate = useNavigate();
  const authOrganizationId = useDashboardStore(
    (store) => store.authOrganizationId
  );

  const buildOrganizationPrefixedPath = useCallback(
    (path: string) =>
      `${buildOrganizationPathPrefix(authOrganizationId ?? "")}/${path}`,
    [authOrganizationId]
  );

  const prefixedNavigate = useCallback(
    ({ to, ...opts }: NavigationOptions) => {
      if (!authOrganizationId) {
        throw new Error(
          "Attempted to navigate to an organization prefixed route without an organization set in the store."
        );
      }

      const isRelativePath = to && `${to}`.startsWith(".");

      navigate({
        ...(to
          ? {
              to: isRelativePath
                ? to
                : buildOrganizationPrefixedPath(to.toString()),
            }
          : {}),
        ...opts,
      });
    },
    [authOrganizationId, buildOrganizationPrefixedPath, navigate]
  );

  return { prefixedNavigate, buildOrganizationPrefixedPath };
};

export default useOrganizationPrefixedNavigation;
