import { FourDigitYear } from "@taxbit-private/datetime";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";
import { FormItemDocumentType } from "./form-items/formItemsSharedRestApiTypes";
import { dashboardFormTypeSchema } from "./shared/dashboardFormType";

export type GetTransactionsOverviewUrlParams = {
  "filters[datetime][$gte]"?: string;
  "filters[datetime][$lte]"?: string;
};

const transactionsByDaySchema = z.object({
  transactionDay: z.string(),
  totalTxnsCreated: z.number(),
});

export type TransactionsByDay = z.infer<typeof transactionsByDaySchema>;

export const getTransactionsOverviewResponseSchema = getPublicApiSuccessSchema(
  z.array(transactionsByDaySchema)
);

export type GetFormItemsOverviewUrlParams = {
  "filters[document_date]": FourDigitYear;
};

const formCountByTypeSchema = z.object({
  documentType: dashboardFormTypeSchema,
  totalFormItemsCreated: z.number(),
});

export type FormCountByType = z.infer<typeof formCountByTypeSchema>;

export const getFormItemsOverviewResponseSchema = getPublicApiSuccessSchema(
  z.array(formCountByTypeSchema)
);

const formItemsYearsSchema = z.record(
  z.string(),
  z.array(z.nativeEnum(FormItemDocumentType))
);

export type FormItemsYears = z.infer<typeof formItemsYearsSchema>;

export const getFormItemsYearsResponseSchema =
  getPublicApiSuccessSchema(formItemsYearsSchema);

export type GetEligibilityOverviewUrlParams = {
  "filters[document_date]": FourDigitYear;
};

const eligibilityByTypeSchema = z.object({
  documentType: dashboardFormTypeSchema,
  totalEligibleAccounts: z.number(),
});

export type EligibilityByType = z.infer<typeof eligibilityByTypeSchema>;

export const getEligibilityOverviewResponseSchema = getPublicApiSuccessSchema(
  z.array(eligibilityByTypeSchema)
);
