import { O, U } from "ts-toolbelt";

/**
 * Creates a single object containing the key/value pairs from an array
 * of Zustand slice objects. Throws an error if a duplicate key is found.
 *
 * TODO: Validate that all slices are unique at compile time with some fancy typing.
 * https://taxbit.atlassian.net/browse/TAX-12989
 */
const createDedupedStoreFromSlices = <
  Slices extends Record<string, unknown>[],
  Slice extends Slices[number],
  SliceValue extends Slice[keyof Slice],
  Result extends U.Merge<O.MergeAll<Slice, Slices>>,
>(
  slices: Slices
) => {
  const entryList = slices.reduce<[string, SliceValue][]>((acc, nextSlice) => {
    const entries = Object.entries(nextSlice) as [string, SliceValue][];
    return [...acc, ...entries];
  }, []);

  const dedupedStore = entryList.reduce((acc, [key, val]) => {
    if (acc[key as keyof Result]) {
      throw new Error(
        `Found duplicate key of "${key}" when creating the Zustand store. Please ensure that keys are not duplicated across separate state slices.`
      );
    } else {
      return {
        ...acc,
        [key]: val,
      };
    }
  }, {} as Result);

  return dedupedStore;
};

export default createDedupedStoreFromSlices;
